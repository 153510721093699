export function checkIfFilesAreTooBig(files, allowedSize) {
  let valid = true;
  if (files) {
    if (Array.isArray(files)) {
      files.map((file) => {
        const size = file.size / 1024 / 1024;
        if (size > allowedSize) {
          valid = false;
        }
      });
    }
  }
  return valid;
}

export function checkIfFilesAreCorrectType(files, allowedTypes) {
  let valid = true;
  if (files) {
    if (Array.isArray(files)) {
      files.map((file) => {
        if (typeof file === "string") {
        } else {
          if (!allowedTypes.includes(file.type)) {
            valid = false;
          }
        }
      });
    }
  }
  return valid;
}
