import { createSlice } from "@reduxjs/toolkit";

import { getRequest } from "../../API/api";

import {
  generalsAlumniData,
  generalsAlumniFilterData,
} from "../../DB/generalsSection";

export const getDropdownForEducationInHeader = (params) => (dispatch) => {
  const success = (data) => {
    dispatch(successDropdownForEducationInHeader(data.data));
    dispatch(successGetlanguageRequestDropdown(params.lang));
  };
  const setError = (error) => {
    console.log(error);
  };

  getRequest({
    url: `board_direction`,
    success,
    setError,
    params,
  });
};

const initialState = {
  generalsAlumniFilter: generalsAlumniFilterData,
  sectionOpinions: null,
  dropdownForEducation: [],
  getlanguageRequestDropdown: null,
};

export const generals = createSlice({
  name: "generals",
  initialState,
  reducers: {
    setSectionOpinions(state, action) {
      state.sectionOpinions = action.payload;
    },

    successDropdownForEducationInHeader(state, action) {
      state.dropdownForEducation = action.payload;
    },
    successGetlanguageRequestDropdown(state, action) {
      state.getlanguageRequestDropdown = action.payload;
    },
  },
});

export const {
  setSectionOpinions,
  successDropdownForEducationInHeader,
  successGetlanguageRequestDropdown,
} = generals.actions;

export default generals.reducer;
