// the date format on which the function works parseDateDayMon : "02.11.2021 13:57"

//db
import { langEN } from "../../DB/translationEN";

export const parseDateDayMonYear = (date) => {
  const year = date.slice(0, 4);
  const day = MakeTwoDigits(date.slice(8, 10));
  const month = MakeMonthToWord(date.slice(5, 7));

  return {
    day: day,
    month: month,
    year: year,
  };
};

const MakeTwoDigits = (num) => {
  if (num < 9) {
    num = num.slice(1, 2);
  }
  return num;
};

const MakeMonthToWord = (month) => {
  // switch (month) {
  //   case "01":
  //     return "months.january";
  //   case "02":
  //     return "months.february";
  //   case "03":
  //     return "months.march";
  //   case "04":
  //     return "months.april";
  //   case "05":
  //     return "months.may";
  //   case "06":
  //     return "months.june";
  //   case "07":
  //     return "months.july";
  //   case "08":
  //     return "months.august";
  //   case "09":
  //     return "months.september";
  //   case "10":
  //     return "months.october";
  //   case "11":
  //     return "months.november";
  //   case "12":
  //     return "months.december";
  //   default:
  //     return month;
  // }
  switch (month) {
    case "01":
      return langEN.months.january;
    case "02":
      return langEN.months.february;
    case "03":
      return langEN.months.march;
    case "04":
      return langEN.months.april;
    case "05":
      return langEN.months.may;
    case "06":
      return langEN.months.june;
    case "07":
      return langEN.months.july;
    case "08":
      return langEN.months.august;
    case "09":
      return langEN.months.september;
    case "10":
      return langEN.months.october;
    case "11":
      return langEN.months.november;
    case "12":
      return langEN.months.december;
    default:
      return month;
  }
};

export const parseDateGetdiffDate = (t, start_date, end_date) => {
  if (start_date === null) return;
  if (end_date === null) return;

  let date1 = new Date(start_date);
  let date2 = new Date(end_date);
  let timeDiff = Math.abs(date2.getTime() - date1.getTime());
  let diffDays = Math.floor(timeDiff / (1000 * 3600 * 24));
  let diffYears = Math.floor(timeDiff / (1000 * 3600 * 24) / 365);
  let diffMonth = Math.floor(timeDiff / (1000 * 3600 * 24) / 30);

  // return diffYears
  //   ? `${diffYears} ${
  //       diffYears < 2
  //         ? `${t("parseDateGetdiffDate.year")}`
  //         : diffYears < 5
  //         ? `${t("parseDateGetdiffDate.years1")}`
  //         : `${t("parseDateGetdiffDate.years2")}`
  //     }`
  //   : diffMonth
  //   ? `${diffMonth} ${
  //       diffYears < 2
  //         ? `${t("parseDateGetdiffDate.month")}`
  //         : diffYears < 5
  //         ? `${t("parseDateGetdiffDate.months1")}`
  //         : `${t("parseDateGetdiffDate.months2")}`
  //     }`
  //   : `${diffDays} ${
  //       diffYears < 2
  //         ? `${t("parseDateGetdiffDate.day")}`
  //         : diffYears < 5
  //         ? `${t("parseDateGetdiffDate.days1")}`
  //         : `${t("parseDateGetdiffDate.days2")}`
  //     }`;

  return diffYears
    ? `${diffYears} ${
        diffYears < 2
          ? langEN.parseDateGetdiffDate.year
          : diffYears < 5
          ? langEN.parseDateGetdiffDate.years1
          : langEN.parseDateGetdiffDate.years2
      }`
    : diffMonth
    ? `${diffMonth} ${
        diffYears < 2
          ? langEN.parseDateGetdiffDate.month
          : diffYears < 5
          ? langEN.parseDateGetdiffDate.months1
          : langEN.parseDateGetdiffDate.months2
      }`
    : `${diffDays} ${
        diffYears < 2
          ? langEN.parseDateGetdiffDate.day
          : diffYears < 5
          ? langEN.parseDateGetdiffDate.days1
          : langEN.parseDateGetdiffDate.days2
      }`;
};
