import { mainLanguageData, languageData } from "../DB/languageData";

export const findUrlLocalization = (pathSplit) => {
  let isFindLocalizationUrl = null;
  pathSplit?.length > 0
    ? pathSplit.map((path) => {
        return languageData.map((lang) => {
          if (lang.key === path) {
            isFindLocalizationUrl = path;
          }

          return lang;
        });
      })
    : (isFindLocalizationUrl = pathSplit);

  return mainLanguageData?.key === isFindLocalizationUrl
    ? null
    : isFindLocalizationUrl;
};
