import React from "react";
import classNames from "classnames";

//styles
import s from "./information-text.module.scss";

const InformationText = ({
  children,
  className,
  width,
  marginTop,
  fontSize,
}) => {
  return (
    <div
      className={classNames(s.root, className)}
      style={{ width: width, marginTop: marginTop, fontSize: fontSize }}
    >
      {children}
    </div>
  );
};

export default InformationText;
