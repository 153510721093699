import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";

import app from "./App/app";
import home from "./Home/home";
import education from "./Education/education";
import generals from "./Generals/generals";
import nedDatabase from "./NedDatabase/nedDatabase";
import ourBoard from "./OurBoard/ourBoard";
import news from "./News/news";
import services from "./Services/services";
import myProfile from "./MyProfile/myProfile";
import ourTeam from "./OurTeam/ourTeam";
import alumni from "./Alumni/Alumni";
import programsPage from "./ProgramsPage/ProgramsPage";

import myData from "./MyData/myData";
import myProfileSectionPost from "./MyProfile/myProfileSectionPost";
import myProfileSectionGet from "./MyProfile/myProfileSectionGet";
import myProfileDeleteElementSections from "./MyProfile/myProfileDeleteElementSections";
import myProfileLogin from "./MyProfile/myProfileLogin";
import newsPrivate from "./NewsPrivate/NewsPrivate";
import myProfileResetPassword from "./MyProfile/myProfileResetPassword";

export const rootReducer = combineReducers({
  myData: myData,
  app: app,
  home: home,
  education: education,
  generals: generals,
  nedDatabase: nedDatabase,
  ourBoard: ourBoard,
  news: news,
  services: services,
  myProfile: myProfile,
  ourTeam: ourTeam,
  myProfileSectionPost: myProfileSectionPost,
  myProfileSectionGet: myProfileSectionGet,
  myProfileLogin: myProfileLogin,
  myProfileDeleteElementSections: myProfileDeleteElementSections,
  alumni: alumni,
  programsPage: programsPage,
  newsPrivate: newsPrivate,
  myProfileResetPassword,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
