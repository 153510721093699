import React from "react";

export const IncludesCheckSvg = () => (
  <svg
    width="13"
    height="11"
    viewBox="0 0 13 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 5.82748L1.40122 3.90598L5.1446 7.20225L11.2964 0L13 1.63039L5.38345 10.6131L0 5.82748Z"
      fill="#40BCD6"
    />
  </svg>
);
