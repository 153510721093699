function createItemMessageFromMass ( items) {
  let message = '';
  items?.map((val, index)=> {
    message += `${val} ${index + 1 !== items.length  ? ',' : '' }`;
  });

  return message;
}

export const getMessagesValidationBack = (dataBack, fields) => {
  if (dataBack && Object.keys(dataBack)?.length > 0) {
    const result = { ...fields };
    Object.entries(dataBack).forEach((item) => {
      result[item[0]] = createItemMessageFromMass(item[1]);
    });
    return result
  } 
}; 