import { createSlice } from "@reduxjs/toolkit";

import { getRequest } from "../../API/api";

export const getAlumni = (params) => (dispatch) => {
  dispatch(setLoadingStatus());

  const success = (data) => {
    dispatch(successAlumniPersons(data.data));
    dispatch(successAlumniPersonsMeta(data.meta));
    dispatch(successAlumniFilters(data.filters));
    dispatch(successGetlanguageRequest(params.lang));
    dispatch(successGetAlumniFilters(params.filters));
  };

  const setError = (error) => {
    dispatch(setErrorMessage(error));
  };

  getRequest({
    url: `alumni`,
    success,
    setError,
    params,
  });
};

const initialState = {
  alumniPersons: [],
  alumniPersonsMeta: {},
  alumniPersonsFilters: [],

  status: null,
  error: null,
  filtersObjForBack: null,
  getlanguageRequest: null,

  getAlumniFiltersRequest: null,
};

export const alumni = createSlice({
  name: "alumni",
  initialState,
  reducers: {
    setLoadingStatus(state) {
      state.status = true;
      state.error = false;
      state.alumniPersons = null;
    },
    setErrorMessage(state, action) {
      state.error = action.payload;
      state.status = false;
    },
    successAlumniPersons(state, action) {
      state.alumniPersons = action.payload;
      state.status = false;
      state.error = null;
    },
    successAlumniFilters(state, action) {
      state.alumniPersonsFilters = [
        {
          id: 0,
          course_code: "ALL",
        },
        ...action.payload,
      ];
    },
    successAlumniPersonsMeta(state, action) {
      state.alumniPersonsMeta = action.payload;
    },
    successGetlanguageRequest(state, action) {
      state.getlanguageRequest = action.payload;
    },
    successGetAlumniFilters(state, action) {
      state.getAlumniFiltersRequest = action.payload;
    },
  },
});
export const {
  setLoadingStatus,
  setErrorMessage,
  successAlumniPersons,
  successAlumniFilters,
  successAlumniPersonsMeta,
  successGetlanguageRequest,
  successGetAlumniFilters,
} = alumni.actions;

export default alumni.reducer;
