import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

//styles
import s from "./ErrorBoundaryComponent.module.scss";

//ui
import { Container } from "../ui";
import { Button } from "../ui";

//Routes
import { path, pathFC } from "../App/Routes/paths";

const language = localStorage.getItem("language_UCGA");

const ErrorComponent = ({ error, resetErrorBoundary }) => {
  const { t } = useTranslation();

  const history = useHistory();

  return (
    <main
      role="alert"
      className={`${s.main} ${
        language === "en" ? s.rootFontOne : s.rootFontTwo
      }`}
    >
      <Container>
        <p>Something went wrong:</p>
        <pre>{error.message}</pre>
        <div className={s.groupBtn}>
          <Button
            onClick={() => resetErrorBoundary()}
            secondary
            width={"250px"}
            sidePadding={0}
            marginTop={"25px"}
            className={s.tryAgain}
          >
            Try again
          </Button>

          <Button
            onClick={() => history.push(pathFC(path.home))}
            secondary
            width={"250px"}
            sidePadding={0}
            marginTop={"25px"}
          >
            {t("btnTitle.homePage")}
          </Button>
        </div>
      </Container>
    </main>
  );
};

export default ErrorComponent;
