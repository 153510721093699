import React, { useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Link, NavLink, useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";

//styles
import s from "./header.module.scss";

//global
import { path, pathFC } from "../../../App/Routes/paths";

//assets
import logo from "../../../../assets/icons/ganeral/logo1_blue_hor-_1_.svg";

//ui
import { Button } from "../../index";
import SwitchLanguage from "../../SwitchLanguage";
import { useScrollBlock } from "../useScrollBlock.jsx";

//DB
import { headerNavigationData } from "../../../../DB/headerData";

//components
import { Dropdown } from "./HeaderDropdown";
import { AdaptiveDropdown } from "./HeaderAdaptiveDropdown";

//svg
import { CloseSvg2 } from "../../svgHtml/CloseSvg2";

//redux
import { logoutProfile } from "../../../../redux/MyProfile/myProfileLogin";
import { getDropdownForEducationInHeader } from "../../../../redux/Generals/generals";

const Header = () => {
  const { t } = useTranslation();

  const history = useHistory();

  const dispatch = useDispatch();

  const isIpadMini = useMediaQuery({ maxWidth: 768 });

  const [isOpenMobileMenu, setIsOpenMobileMenu] = React.useState(false);

  const [allowScroll, blockScroll] = useScrollBlock();

  const onCloseClick = () => setIsOpenMobileMenu(false);

  let token = localStorage.getItem("access_token_UCGA");

  const { language, isLoggin } = useSelector((state) => state.app);

  const { slugUser } = useSelector((state) => state.myProfile);

  const { dropdownForEducation, getlanguageRequestDropdown } = useSelector(
    (state) => state.generals
  );

  useEffect(() => {
    const params = {
      lang: language,
      perPage: 20,
    };
    if (getlanguageRequestDropdown !== params.lang) {
      dispatch(getDropdownForEducationInHeader(params));
    }
  }, [language]);

  useEffect(() => {
    if (isOpenMobileMenu) {
      allowScroll();
    } else {
      blockScroll();
    }
    if (!isIpadMini) {
      setIsOpenMobileMenu(false);
    }
  }, [isOpenMobileMenu, isIpadMini]);

  return (
    <>
      <header className={s.root}>
        <div className={s.container}>
          <div className={s.rootInner}>
            <Link to={pathFC(path.home)} className={s.logo}>
              <img src={logo} alt="logo" />
            </Link>
            {!isIpadMini && <SwitchLanguage />}
            {!isIpadMini ? (
              <nav className={s.desktop}>
                {headerNavigationData && (
                  <ul className={s.menu}>
                    {headerNavigationData.length > 0 &&
                      headerNavigationData.map((item) => {
                        return !item.btn ? (
                          !item.isAdaptive && (
                            <li key={item.id}>
                              <NavLink
                                to={item.link}
                                className={s.link}
                                exact={item.exact}
                              >
                                {/* {item.dropdownTitle ? (
                                  <Dropdown
                                    dropdownTitle={item.dropdownTitle}
                                    dropdown={item.dropdown}
                                  />
                                ) : (
                                  t(item.title)
                                )} */}
                                {item.dropdownTitle && dropdownForEducation ? (
                                  <Dropdown
                                    dropdownTitle={item.dropdownTitle}
                                    dropdown={dropdownForEducation}
                                  />
                                ) : (
                                  t(item.title)
                                )}
                              </NavLink>
                            </li>
                          )
                        ) : (
                          <li key={item.id}>
                            {token && item.link === pathFC(path.myData) && (
                              <Button
                                className={s.btn}
                                onClick={() => history.push(item.link)}
                                tertiary
                                small
                              >
                                {t(item.title)}
                              </Button>
                            )}

                            {!token && item.link === pathFC(path.login) && (
                              <Button
                                className={s.btn}
                                onClick={() => history.push(item.link)}
                                tertiary
                                small
                              >
                                {t(item.title)}
                              </Button>
                            )}
                          </li>
                        );
                      })}
                  </ul>
                )}
              </nav>
            ) : (
              <nav className={s.mobile}>
                {!isOpenMobileMenu ? (
                  <div
                    className={classNames(s.burger)}
                    onClick={() => setIsOpenMobileMenu(!isOpenMobileMenu)}
                  >
                    <span />
                    <span />
                    <span />
                  </div>
                ) : (
                  <div className={classNames(s.close)} onClick={onCloseClick}>
                    <CloseSvg2 width={"17"} height={"17"} bold={"0.8"} />
                  </div>
                )}
              </nav>
            )}
          </div>
        </div>
      </header>
      {isIpadMini && (
        <div
          className={`${s.menuWrapper} ${
            isOpenMobileMenu && isIpadMini && s.menuWrapperActive
          }`}
        >
          <div className={s.menuMobileTop}></div>
        <SwitchLanguage />
          {headerNavigationData && (
            <ul className={s.menuAdaptive}>
              {headerNavigationData.length > 0 &&
                headerNavigationData.map((item) => {
                  return (
                    !item.btn && (
                      <Fragment key={item.id}>
                        {(
                          path.privateNews === item.link
                            ? slugUser?.news_count > 0
                              ? true
                              : false
                            : true
                        ) ? (
                          item.link === pathFC(path.myData) ||
                          item.link === pathFC(path.graduateWork) ||
                          item.link === pathFC(path.studyingMaterials) ||
                          item.link === pathFC(path.privateNews) ? (
                            <>
                              {token && (
                                <li>
                                  <HeaderMobileItemLink
                                    item={item}
                                    dropdownForEducation={dropdownForEducation}
                                    isOpenMobileMenu={isOpenMobileMenu}
                                    onCloseClick={onCloseClick}
                                    setIsOpenMobileMenu={setIsOpenMobileMenu}
                                  />
                                </li>
                              )}
                            </>
                          ) : (
                            <>
                              {
                                <li>
                                  <HeaderMobileItemLink
                                    item={item}
                                    dropdownForEducation={dropdownForEducation}
                                    isOpenMobileMenu={isOpenMobileMenu}
                                    onCloseClick={onCloseClick}
                                    setIsOpenMobileMenu={setIsOpenMobileMenu}
                                  />
                                </li>
                              }
                            </>
                          )
                        ) : (
                          <li>
                            {token && item.link === pathFC(path.home) && (
                              <Button
                                fontSize={"18px"}
                                className={s.btn}
                                width={"240px"}
                                sidePadding={0}
                                onClick={() => {
                                  dispatch(logoutProfile());
                                  history.push(item.link);
                                  // window.location.reload();
                                }}
                                tertiary
                                small
                              >
                                {t(item.title)}
                              </Button>
                            )}
                            {!token && item.link === pathFC(path.login) && (
                              <Button
                                fontSize={"18px"}
                                className={s.btn}
                                width={"240px"}
                                onClick={() => {
                                  setIsOpenMobileMenu(false);
                                  history.push(item.link);
                                }}
                                tertiary
                                small
                                key={item.id}
                              >
                                {t(item.title)}
                              </Button>
                            )}
                          </li>
                        )}
                      </Fragment>
                    )
                  );
                })}
              {!token && (
                <li>
                  <Button
                    fontSize={"18px"}
                    className={s.btn}
                    width={"240px"}
                    onClick={() => {
                      setIsOpenMobileMenu(false);
                      history.push(pathFC(path.login));
                    }}
                    tertiary
                    small
                  >
                    {t("logIn")}
                  </Button>
                </li>
              )}
              {token && (
                <li>
                  <Button
                    fontSize={"18px"}
                    className={s.btn}
                    width={"140px"}
                    sidePadding={0}
                    onClick={() => {
                      setIsOpenMobileMenu(false);
                      history.push(pathFC(path.home));
                      dispatch(logoutProfile());
                    }}
                    tertiary
                    small
                  >
                    {t("generals.singOut")}
                  </Button>
                </li>
              )}
            </ul>
          )}
        </div>
      )}

      {/* )} */}
    </>
  );
};

export default Header;

const HeaderMobileItemLink = ({
  item,
  dropdownForEducation,
  isOpenMobileMenu,
  onCloseClick,
  setIsOpenMobileMenu,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {item?.isLine && <hr className={s.line} />}
      <li key={item.id}>
        {item?.dropdownTitle && dropdownForEducation ? (
          <>
            <AdaptiveDropdown
              isOpenMobileMenu={isOpenMobileMenu}
              onLinkClick={onCloseClick}
              dropdownTitle={item.dropdownTitle}
              dropdown={dropdownForEducation}
              dropdownMainLink={item.link}
            />
          </>
        ) : (
          <NavLink
            to={item.link}
            onClick={() => !item?.dropdownTitle && setIsOpenMobileMenu(false)}
          >
            {item.dropdownTitle ? (
              <AdaptiveDropdown
                isOpenMobileMenu={isOpenMobileMenu}
                onLinkClick={onCloseClick}
                dropdownTitle={item.dropdownTitle}
                dropdown={item.dropdown}
              />
            ) : (
              t(item.title)
            )}
          </NavLink>
        )}
      </li>
    </>
  );
};
