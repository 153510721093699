import React from "react";
import { useSelector } from "react-redux";

//styles
import styles from "./SwitchLanguage.module.scss";

//db
import { languageData } from "../../../DB/languageData";

export const SwitchLanguage = () => {
  const language = useSelector((state) => state.app.language);

  const changeLanguage = (language) => {
    localStorage.setItem("language_UCGA", language);
    // setTimeout(() => {
    //   window.location.reload();
    // }, 1000);
    document.location.reload();
  };
  return (
    <div className={styles.wrapper}>
      {languageData.map(({ key = "", label = "" }) => (
        <LanguageBtn
          key={key}
          keyLang={key}
          label={label=="uk"?"ua":label}
          lang={language}
          changeLanguage={changeLanguage}
        />
      ))}
    </div>
  );
};

const LanguageBtn = ({ keyLang, label, lang, changeLanguage }) => {
  return (
    <div
      className={`${styles.language} ${lang === keyLang && styles.active}`}
      onClick={() => changeLanguage(keyLang)}
    >
      {label}
    </div>
    //   <Link
    //   className={`${styles.language} ${lang === keyLang && styles.active}`}
    //   onClick={() => changeLanguage(keyLang)}
    //   to={`${mainLanguageData?.key === keyLang ? "/" : `/${keyLang}/`}`}
    // >
    //   {label}
    // </Link>
  );
};
