import React from "react";
import PropTypes from "prop-types";

//styles
import s from "./includes.module.scss";

//svg
import { IncludesCheckSvg } from "../svgHtml/IncludesCheckSvg";

const Includes = ({ text, className, marginTop, mainText, alignItems }) => {
  return (
    <div
      className={s.root + " " + className}
      style={{ marginTop: marginTop, alignItems: alignItems }}
    >
      <div>
        <IncludesCheckSvg />
      </div>
      <div className={s.text}>
        <span className={s.mainText}>{mainText}</span> {text}
      </div>
    </div>
  );
};

Includes.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  marginTop: PropTypes.string,
};

export default Includes;
