import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

//styles
import s from "./button.module.scss";

const Button = ({
  primary,
  tertiary,
  disabled,
  onClick,
  secondary,
  children,
  small,
  className,
  marginTop,
  fontSize,
  sidePadding,
  width,
  active = false,
  maxWidth,
}) => {
  return (
    <button
      className={`${classNames([s.root], className, {
        [s.btnLearnMore]: primary,
        [s.btnApplicationForm]: secondary,
        [s.btnShowMore]: tertiary,
        [s.small]: small,
        [s.disabled]: disabled,
        [className]: className,
      })} ${active && s.active}`}
      disabled={disabled}
      onClick={onClick}
      style={{
        marginTop: marginTop,
        fontSize: fontSize,
        padding: `0 ${sidePadding}px`,
        width: width,
        maxWidth: maxWidth ? maxWidth : "",
      }}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  primary: PropTypes.bool, //learn
  secondary: PropTypes.bool, //application
  tertiary: PropTypes.bool, //show
  small: PropTypes.bool,
  marginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontSize: PropTypes.string,
  sidePadding: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Button;
