import React from "react";

export const FacebookSvg = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.4003 11.2451C21.4003 5.33599 16.6092 0.544922 10.7002 0.544922C4.79107 0.544922 0 5.33599 0 11.2451C0 16.5854 3.91231 21.0123 9.0284 21.8158V14.3388H6.31085V11.2451H9.0284V8.88716C9.0284 6.20573 10.6264 4.72357 13.0701 4.72357C14.2408 4.72357 15.4656 4.93272 15.4656 4.93272V7.56601H14.1159C12.7872 7.56601 12.3719 8.3906 12.3719 9.23777V11.2451H15.3392L14.8653 14.3388H12.3719V21.8158C17.488 21.0138 21.4003 16.5869 21.4003 11.2451Z"
      fill="#A3B5BF"
    />
    <path
      d="M14.863 14.3389L15.337 11.2451H12.3697V9.23783C12.3697 8.39217 12.7835 7.56607 14.1136 7.56607H15.4634V4.93279C15.4634 4.93279 14.2385 4.72363 13.0679 4.72363C10.6242 4.72363 9.02614 6.20429 9.02614 8.88723V11.2451H6.30859V14.3389H9.02614V21.8159C9.57086 21.9017 10.1291 21.9453 10.6979 21.9453C11.2667 21.9453 11.8249 21.9002 12.3697 21.8159V14.3389H14.863Z"
      fill="white"
    />
  </svg>
);
export const LinkedInSvg = () => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.3712 0H1.81903C0.818562 0 0 0.818562 0 1.81903V16.3712C0 17.3717 0.818562 18.1903 1.81903 18.1903H16.3712C17.3717 18.1903 18.1903 17.3717 18.1903 16.3712V1.81903C18.1903 0.818562 17.3717 0 16.3712 0ZM5.45708 15.4617H2.72854V7.27611H5.45708V15.4617ZM4.09281 5.72993C3.1833 5.72993 2.45569 5.00232 2.45569 4.09281C2.45569 3.1833 3.1833 2.45569 4.09281 2.45569C5.00232 2.45569 5.72993 3.1833 5.72993 4.09281C5.72993 5.00232 5.00232 5.72993 4.09281 5.72993ZM15.4617 15.4617H12.7332V10.6413C12.7332 9.91373 12.0965 9.27704 11.3689 9.27704C10.6413 9.27704 10.0046 9.91373 10.0046 10.6413V15.4617H7.27611V7.27611H10.0046V8.36752C10.4594 7.63991 11.4599 7.0942 12.2784 7.0942C14.0065 7.0942 15.4617 8.54943 15.4617 10.2775V15.4617Z"
      fill="#A3B5BF"
    />
  </svg>
);
