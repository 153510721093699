import { createSlice } from "@reduxjs/toolkit";

//api
import { postRequest } from "../../API/api";

import { setIsLoggin } from "../App/app";
import { path, pathFC } from "../../components/App/Routes/paths";
import { getSlugUser } from "./myProfile";

import { getMessagesValidationBack } from "../../utils/getMessagesValidationBack";

const token = localStorage.getItem("access_token_UCGA");

export const profileLogin = (obj) => (dispatch) => {
  dispatch(setLoadingStatus());
  const success = (data) => {
    localStorage.setItem("access_token_UCGA", data.access_token);
    dispatch(setIsLoggin(true));
    dispatch(successPostProfileLogin(data));
    dispatch(getSlugUser({ token: data.access_token }));
    setTimeout(() => {
      window.location.href = pathFC(path.myData);
    }, 0);
  };

  const setError = (error) => {
    dispatch(setErrorPostProfileLogin(error));
  };

  postRequest({
    url: `auth/login`,
    success,
    setError,
    obj,
  });
};

export const profileRegister = (obj) => (dispatch) => {
  dispatch(setLoadingStatusRegister());
  const success = (data) => {
    dispatch(successPostProfileRegister(data));
  };

  const setError = (error) => {

    dispatch(setErrorPostProfileRegister(getMessagesValidationBack(error.response.data, {})));

  };

  postRequest({
    url: `/auth/register`,
    success,
    setError,
    obj,
  });
};

export const logoutProfile = () => (dispatch) => {
  localStorage.removeItem("access_token_UCGA");
  dispatch(setIsLoggin(false));
};

const initialState = {
  profileLoginData: {},
  errorProfileLogin: null,
  status: false,
  profileRegisterData: {},
  errorProfileRegister: null,
  statusRegister: false,
  isLoggin: token ? true : false,
};

export const myProfileLogin = createSlice({
  name: "myProfileLogin",
  initialState,
  reducers: {
    setLoadingStatus(state) {
      state.status = true;
      state.error = null;
    },
    successPostProfileLogin(state, action) {
      state.profileLoginData = action.payload;
      state.status = false;
      state.errorProfileLogin = null;
    },
    setErrorPostProfileLogin(state, action) {
      state.errorProfileLogin = action.payload;
      state.status = false;
    },
    setLoadingStatusRegister(state) {
      state.statusRegister = true;
      state.errorProfileRegister = null;
    },
    successPostProfileRegister(state, action) {
      state.profileRegisterData = action.payload;
      state.statusRegister = false;
      state.errorProfileRegister = null;
    },
    setErrorPostProfileRegister(state, action) {
      state.errorProfileRegister = action.payload;
      state.statusRegister = false;
    },
  },
});
export const {
  setLoadingStatus,
  successPostProfileLogin,
  setErrorPostProfileLogin,
  setErrorPostProfileRegister,
  successPostProfileRegister,
  setLoadingStatusRegister,
} = myProfileLogin.actions;

export default myProfileLogin.reducer;
