import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

//styles
import s from "./btn-learn-more.module.scss";

//svg
import { BtnLearnMoreArrowRigthSvg } from "../../svgHtml/BtnLearnMoreArrowRigthSvg";

const BtnLearnMore = ({ to, className, disabled, text, background }) => {
  const { t } = useTranslation();
  return (
    <Link to={to}>
      <div className={classNames(s.rootOuter, className)}>
        <div
          className={`${classNames(s.root, {
            [s.active]: !disabled,
            [s.disabled]: disabled,
          })} ${!className && s.backgroundDark}`}
        >
          <span className={s.title}>
            {text ? text : t("generals.learnMore")}
            <BtnLearnMoreArrowRigthSvg />
          </span>
        </div>
      </div>
    </Link>
  );
};

BtnLearnMore.propTypes = {
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default BtnLearnMore;
