import { createSlice } from "@reduxjs/toolkit";

//api
import { getRequest, postRequest } from "../../API/api";

let token = localStorage.getItem("access_token_UCGA");

export const getDropdownsForForms = (params) => (dispatch) => {
  const success = (data) => {
    dispatch(successGetDropdownsForForms(data));
  };

  const setError = (error) => {
    dispatch(setErrorMessageDropdownsForForms(error));
  };

  getRequest({
    url: `dropdowns`,
    success,
    setError,
    params,
  });
};

export const getSlugUser = (params) => (dispatch) => {
  const success = (data) => {
    dispatch(successGetSlugUser(data));
  };

  const setError = (error) => {
    dispatch(setErrorMessageSlugUser(error));
  };

  getRequest({
    url: `ned_db/slug`,
    success,
    setError,
    params,
    headers: {
      Authorization: `Bearer ${params?.token || token}`,
    },
  });
};

export const postFileDiplom = (path, params, obj) => (dispatch) => {
  dispatch(setLoadingStatusPostFileDiplom());
  const success = (data) => {
    dispatch(successPostFileDiplom(data));
  };

  const setError = (error) => {
    dispatch(setErrorPostFileDiplom(error));
  };

  postRequest({
    url: `graduate_work/${path}`,
    success,
    setError,
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    obj,
  });
};
const initialState = {
  dataFormsMyProfile: {},
  dropdownsForForms: {},
  errorDropdownsForForms: {},
  slugUser: {},
  errorSlugUser: null,

  statusPostFileDiplom: false,
  errorPostFileDiplom: null,
  dataPostFileDiplom: {},
};

export const myProfile = createSlice({
  name: "myProfile",
  initialState,
  reducers: {
    setDataFormsEnMyProfile(state, action) {
      state.dataFormsEnMyProfile = {
        ...state.dataFormsEnMyProfile,
        ...action.payload,
      };
    },
    setDataFormsMyProfile(state, action) {
      state.dataFormsMyProfile = {
        ...state.dataFormsMyProfile,
        ...action.payload,
      };
    },
    setErrorMessageDropdownsForForms(state, action) {
      state.errorDropdownsForForms = action.payload;
    },
    successGetDropdownsForForms(state, action) {
      state.dropdownsForForms = action.payload;
      state.errorDropdownsForForms = null;
    },
    successGetSlugUser(state, action) {
      state.slugUser = action.payload;
      state.errorSlugUser = null;
    },
    setErrorMessageSlugUser(state, action) {
      state.errorSlugUser = action.payload;
    },

    setLoadingStatusPostFileDiplom(state) {
      state.statusPostFileDiplom = true;
      state.errorPostFileDiplom = null;
    },
    successPostFileDiplom(state, action) {
      state.dataPostFileDiplom = action.payload;
      state.statusPostFileDiplom = false;
      state.errorPostFileDiplom = null;
    },
    setErrorPostFileDiplom(state, action) {
      state.errorPostFileDiplom = action.payload;
      state.statusPostFileDiplom = false;
    },
  },
});
export const {
  setDataFormsEnMyProfile,
  setDataFormsMyProfile,
  successGetDropdownsForForms,
  setErrorMessageDropdownsForForms,
  successGetSlugUser,
  setErrorMessageSlugUser,
  setLoadingStatusPostFileDiplom,
  successPostFileDiplom,
  setErrorPostFileDiplom,
} = myProfile.actions;

export default myProfile.reducer;
