import styled from "styled-components";

const StyledInputWidth = styled.div`
  width: ${(props) => props.width.xl}; !important;
  margin-left: ${(props) => props?.marginLeft?.xl} !important;
  margin-right: ${(props) => props?.marginRight?.xl} !important;
  margin-top: ${(props) => props?.marginTop?.xl} !important;
  @media (max-width: 1024px) {
    width: ${(props) => props.width.lg} !important;
    margin-right: ${(props) => props?.marginRight?.lg} !important;
    margin-top: ${(props) => props?.marginTop?.lg} !important;
  }
  @media (max-width: 768px) {
    width: ${(props) => props.width.md} !important;
    margin-right: ${(props) => props?.marginRight?.md} !important;
    margin-top: ${(props) => props?.marginTop?.md} !important;
  }
  @media (max-width: 576px) {
    width: ${(props) => props.width.mdMinus} !important;
    margin-top: ${(props) => props?.marginTop?.mdMinus} !important;
  }
  @media (max-width: 464px) {
    width: ${(props) => props.width.smPlus} !important;
    margin-top: ${(props) => props?.marginTop?.smPlus} !important;
  }
  @media (max-width: 320px) {
    width: ${(props) => props.width.sm} !important;
  }
`;

export default StyledInputWidth;
