import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import classNames from "classnames";

//styles
import s from "./crumbs.module.scss";

//ui
import { Button } from "../../ui";

//svg
import { CrumbsGraduateWorkSvg } from "../svgHtml/CrumbsGraduateWorkSvg";
import { CrumbsMyDataSvg } from "../svgHtml/CrumbsMyDataSvg";
import { CrumbsStudyingMaterialsSvg } from "../svgHtml/CrumbsStudyingMaterialsSvg";

//redux
import { logoutProfile } from "../../../redux/MyProfile/myProfileLogin";

//global
import { path, pathFC } from "../../App/Routes/paths";

const Crumbs = ({
  disabled,
  studyingMaterials,
  myData,
  graduateWork,
  privateNews,
}) => {
  const history = useHistory();

  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <div className={classNames(s.root)}>
      <div className={s.navigaionProfile}>
        {myData && (
          <NavLink
            to={pathFC(path.myData)}
            className={classNames(s.navigaionProfileItem, {
              [s.active]: disabled.myData,
              [s.disabled]: disabled.myData,
            })}
          >
            <CrumbsMyDataSvg s={s} />
            <span>{t("generals.myData")}</span>
          </NavLink>
        )}

        {studyingMaterials && (
          <NavLink
            to={pathFC(path.studyingMaterials)}
            className={classNames(s.navigaionProfileItem, {
              [s.active]: disabled?.studyingMaterials,
              [s.disabled]: disabled?.studyingMaterials,
            })}
          >
            <CrumbsStudyingMaterialsSvg s={s} />
            <span>{t("generals.studyingMaterials")}</span>
          </NavLink>
        )}
        {graduateWork && (
          <NavLink
            to={pathFC(path.graduateWork)}
            className={classNames(s.navigaionProfileItem, {
              [s.active]: disabled?.graduateWork,
              [s.disabled]: disabled?.graduateWork,
            })}
          >
            <CrumbsGraduateWorkSvg s={s} />
            <span>{t("generals.graduateWork")}</span>
          </NavLink>
        )}
        {privateNews > 0 && (
          <NavLink
            to={pathFC(path.privateNews)}
            className={classNames(s.navigaionProfileItem, {
              [s.active]: disabled?.privateNews,
              [s.disabled]: disabled?.privateNews,
            })}
          >
            <CrumbsGraduateWorkSvg s={s} />
            <span>Private News</span>
          </NavLink>
        )}
      </div>

      <Button
        fontSize={"18px"}
        className={s.btn}
        width={"140px"}
        sidePadding={0}
        onClick={() => {
          dispatch(logoutProfile());
          history.push(pathFC(path.home));
        }}
        tertiary
        small
      >
        {t("generals.singOut")}
      </Button>
    </div>
  );
};

export default Crumbs;
