import React from "react";

//styles
import styles from "./Loader.module.scss";

import logo from "../../../assets/icons/ganeral/logo1_blue_hor-_1_.svg";

export const Loader = () => {
  return (
    <div className={styles.loader}>
      {" "}
      <img src={logo} alt="logo" />
    </div>
  );
};
