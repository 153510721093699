import { createSlice } from "@reduxjs/toolkit";

import { getRequest } from "../../API/api";
import { newsFiltersData } from "../../DB/newsData";

let token = localStorage.getItem("access_token_UCGA");

export const getNewsPrivate = (params) => (dispatch) => {
  dispatch(setLoadingNews());

  const success = (data) => {
    dispatch(successNews(data.data));
    dispatch(successNewsMeta(data.meta));
  };

  const setError = (error) => {
    dispatch(setErrorMessageNews(error));
  };

  getRequest({
    url: `private_news_page`,
    success,
    setError,
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getNewsPrivateItem = (slug, params) => (dispatch) => {
  dispatch(setLoadingNewsItem());

  const success = (data) => {
    dispatch(successNewsItem(data.data));
  };

  const setError = (error) => {
    dispatch(setErrorMessageNewsItem(error));
  };

  getRequest({
    url: `private_news_page/${slug}`,
    success,
    setError,
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const initialState = {
  newsPrivate: [],
  newsFilters: newsFiltersData,

  statusNewsPrivate: null,
  errorNewsPrivate: null,
  newsPrivateItem: {},
  statusNewsPrivateItem: null,
  errorNewsPrivateItem: null,
  newsPrivateMeta: {},
};

export const newsPrivate = createSlice({
  name: "newsPrivate",
  initialState,
  reducers: {
    setLoadingNews(state) {
      state.statusNewsPrivate = true;
      state.errorNewsPrivate = false;
    },
    setErrorMessageNews(state, action) {
      state.errorNewsPrivate = action.payload;
      state.statusNewsPrivate = false;
    },
    successNews(state, action) {
      state.newsPrivate = action.payload;
      state.statusNewsPrivate = false;
      state.errorNewsPrivate = null;
    },
    successNewsMeta(state, action) {
      state.newsPrivateMeta = action.payload;
    },
    setLoadingNewsItem(state) {
      state.statusNewsPrivateItem = true;
      state.errorNewsPrivateItem = null;
    },
    setErrorMessageNewsItem(state, action) {
      state.errorNewsPrivateItem = action.payload;
      state.statusNewsPrivateItem = false;
    },
    successNewsItem(state, action) {
      state.newsPrivateItem = action.payload;
      state.statusNewsPrivateItem = false;
      state.errorNewsPrivateItem = null;
    },
  },
});

export const {
  setLoadingNews,
  setErrorMessageNews,
  successNews,
  successNewsMeta,
  setLoadingNewsItem,
  setErrorMessageNewsItem,
  successNewsItem,
} = newsPrivate.actions;

export default newsPrivate.reducer;
