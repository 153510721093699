import React from "react";

export const CrumbsGraduateWorkSvg = ({ s }) => (
  <svg
    className={s.svg}
    width="24"
    height="27"
    viewBox="0 0 24 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.1921 7.69445C17.9801 3.89629 12.5926 1.8835 7.76394 3.64387C5.26379 4.61017 3.24929 6.49808 1.80828 8.70677C0.355285 10.9352 -0.262103 13.4462 0.102475 16.0862C0.236863 17.0577 0.476368 18.0595 0.935417 18.9364C1.44237 19.9053 2.25402 20.7717 2.96721 21.596C4.69829 23.597 6.7527 25.4744 9.29012 26.3986C14.5965 28.3325 20.3472 25.3942 22.7582 20.5574C24.8699 16.3175 24.2498 11.3099 21.1921 7.69445Z"
      fill="#ECF8FB"
    />
    <path
      d="M21.1921 7.69445C17.9801 3.89629 12.5926 1.8835 7.76394 3.64387C5.26379 4.61017 3.24929 6.49808 1.80828 8.70677C0.355285 10.9352 -0.262103 13.4462 0.102475 16.0862C0.236863 17.0577 0.476368 18.0595 0.935417 18.9364C1.44237 19.9053 2.25402 20.7717 2.96721 21.596C4.69829 23.597 6.7527 25.4744 9.29012 26.3986C14.5965 28.3325 20.3472 25.3942 22.7582 20.5574C24.8699 16.3175 24.2498 11.3099 21.1921 7.69445Z"
      fill="#ECF8FB"
    />
    <path d="M7.53073 17.9238C7.15501 19.0176 6.22265 19.975 5.37101 18.5222C5.03703 17.9544 4.97024 17.2085 4.90623 16.5656C4.77542 15.2603 4.81995 13.93 4.80047 12.6219C4.76707 10.0475 4.73089 7.47308 4.69749 4.89589C4.67801 3.37907 4.96467 1.30006 3.46455 0.351007C2.06741 -0.531251 0.606262 0.364922 0.0885961 1.75928C-0.111791 2.29921 0.52555 3.13416 0.809431 2.36879C1.20742 1.29728 2.52385 0.838058 3.39498 1.67022C3.98779 2.2352 3.85976 3.35402 3.87089 4.09156C3.89038 5.472 3.90708 6.84966 3.92656 8.2301C3.95996 10.7377 3.92934 13.2565 4.0351 15.7641C4.09355 17.1974 4.21044 19.3432 5.49069 20.2811C6.89339 21.3081 7.85915 19.6911 8.25435 18.5389C8.43526 17.9934 7.79513 17.1529 7.53073 17.9238Z" />
    <path d="M2.70528 1.28337C5.17115 1.29172 7.63423 1.33069 10.1001 1.4114C11.1605 1.4448 12.2209 1.48654 13.2812 1.53664C14.6979 1.60344 16.9995 1.23884 17.5422 3.05902C17.8567 4.11384 17.7371 5.50541 17.8039 6.59919C17.879 7.77924 17.9374 8.96208 17.9848 10.1449C18.085 12.6915 18.1156 15.2381 18.0933 17.7874C18.0877 18.5556 18.9032 18.5305 18.9088 17.768C18.9366 14.7649 18.8837 11.7619 18.739 8.76169C18.6694 7.30611 18.5776 5.8533 18.4662 4.40328C18.3939 3.47371 18.4662 2.25469 17.9597 1.41975C17.4866 0.640467 16.7017 0.506876 15.8584 0.434514C14.4669 0.312055 13.0586 0.284224 11.6614 0.228561C8.66956 0.108885 5.67211 0.0504392 2.67466 0.0393066C2.12638 0.0393066 2.14865 1.28059 2.70528 1.28337Z" />
    <path d="M7.75332 18.8225C12.596 18.9478 17.4387 18.9672 22.2814 18.881C22.8324 18.8698 22.8046 17.6285 22.2507 17.6369C17.4081 17.7232 12.5654 17.7037 7.72271 17.5784C7.17721 17.5673 7.19391 18.8086 7.75332 18.8225Z" />
    <path d="M21.8624 18.1492C21.2556 19.371 20.6211 19.794 19.1822 19.7634C18.6116 19.7523 18.0383 19.7356 17.4678 19.7217C16.0011 19.691 14.5343 19.666 13.0676 19.6354C10.8467 19.5881 8.51717 19.6994 6.3157 19.3598C5.79803 19.2791 5.78968 20.5176 6.34631 20.6039C8.90403 20.9991 11.6176 20.8516 14.1976 20.9017C15.7394 20.9323 17.2813 20.9602 18.8232 20.9963C20.66 21.0409 21.6202 20.7013 22.458 19.0119C22.689 18.5611 22.1908 17.4896 21.8624 18.1492Z" />
    <path d="M0.420323 3.07578C1.695 3.23998 2.96969 3.30121 4.25272 3.2539C4.80378 3.23441 4.77317 1.99035 4.2221 2.00983C2.93907 2.05436 1.66439 1.99591 0.389708 1.83171C-0.133524 1.76491 -0.136307 3.00341 0.420323 3.07578Z" />
    <path d="M15.7711 9.41567C15.2228 8.73101 14.8944 7.9573 14.8554 7.07782C14.822 6.31802 14.0065 6.32916 14.0399 7.09731C14.0928 8.27458 14.4769 9.38784 15.2172 10.3091C15.6876 10.8963 16.1023 9.82479 15.7711 9.41567Z" />
    <path d="M13.6527 10.398C14.1398 9.42389 14.4821 8.4136 14.6825 7.34488C14.8049 6.68805 14.0396 6.22605 13.8865 7.03316C13.7251 7.90151 13.4468 8.74202 13.0515 9.53243C12.9207 9.79405 12.8818 10.1614 13.0738 10.4119C13.2269 10.6123 13.5219 10.6624 13.6527 10.398Z" />
    <path d="M12.5376 8.82829C13.6981 8.90622 14.8532 8.87839 16.0109 8.74479C16.5648 8.68078 16.5203 7.4395 15.9803 7.50073C14.8225 7.63432 13.6703 7.66215 12.507 7.58422C11.967 7.54804 11.9782 8.78932 12.5376 8.82829Z" />
    <path d="M15.5598 9.16233C15.1256 8.95081 14.736 8.67806 14.3797 8.35243C14.1598 8.15205 13.9066 8.36913 13.8259 8.589C13.7257 8.86175 13.7702 9.23191 13.9929 9.43508C14.4048 9.8108 14.8695 10.1197 15.3705 10.3674C15.6377 10.4982 15.8297 10.1253 15.8631 9.92213C15.9077 9.6466 15.8297 9.29592 15.5598 9.16233Z" />
    <path d="M13.8572 10.3926C14.0409 10.0503 14.2636 9.74135 14.5363 9.46304C14.7479 9.24873 14.7757 8.87858 14.6616 8.60861C14.5725 8.39431 14.3081 8.18279 14.0966 8.39987C13.7682 8.73664 13.4815 9.11514 13.2589 9.52705C13.1169 9.79145 13.1002 10.1533 13.2811 10.4065C13.4286 10.6208 13.7209 10.6431 13.8572 10.3926Z" />
    <path d="M12.8076 8.84236C13.1026 8.91193 13.392 8.99543 13.6787 9.09562C14.2465 9.29044 14.3606 8.06307 13.8652 7.89052C13.5785 7.79032 13.2891 7.70683 12.994 7.63725C12.7129 7.57046 12.5348 7.82929 12.5014 8.08256C12.4736 8.30521 12.5209 8.77556 12.8076 8.84236Z" />
    <path d="M15.7032 7.60941C15.3413 7.73744 14.9823 7.86546 14.6205 7.99349C14.1307 8.16604 14.2949 9.39063 14.8682 9.18746C15.23 9.05943 15.5891 8.93141 15.9509 8.80338C16.4379 8.63083 16.2765 7.40902 15.7032 7.60941Z" />
    <path d="M12.5935 8.91474C13.011 8.97597 13.4285 9.0372 13.8459 9.09843C14.1354 9.14017 14.2439 8.66425 14.2384 8.46665C14.2328 8.1939 14.1103 7.89889 13.8153 7.85436C13.3979 7.79313 12.9804 7.7319 12.5629 7.67067C12.2735 7.62892 12.1649 8.10484 12.1705 8.30245C12.1761 8.57798 12.2985 8.87299 12.5935 8.91474Z" />
    <path d="M12.2151 4.37817C10.4868 4.37817 8.75847 4.37817 7.03013 4.37817C6.48185 4.37817 6.50412 5.62224 7.06075 5.62224C8.78908 5.62224 10.5174 5.62224 12.2458 5.62224C12.794 5.62224 12.7718 4.37817 12.2151 4.37817Z" />
    <path d="M9.62527 8.29968C8.76249 8.29968 7.89693 8.29968 7.03416 8.29968C6.48588 8.29968 6.50814 9.54375 7.06477 9.54375C7.92755 9.54375 8.79311 9.54375 9.65588 9.54375C10.2042 9.54375 10.1819 8.29968 9.62527 8.29968Z" />
    <path d="M15.5868 12.224C12.7368 12.224 9.8841 12.224 7.03416 12.224C6.48588 12.224 6.50814 13.4681 7.06477 13.4681C9.91472 13.4681 12.7674 13.4681 15.6174 13.4681C16.1657 13.4681 16.1434 12.224 15.5868 12.224Z" />
  </svg>
);
