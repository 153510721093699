import { createSlice } from "@reduxjs/toolkit";

import { getRequest } from "../../API/api";

export const getHomePage = (slug, params) => (dispatch) => {
  dispatch(setLoadingStatus(true));

  const success = (data) => {
    dispatch(successGetPage(data.data));
    dispatch(successGetlanguageRequest(params.lang));
  };

  const setError = (error) => {
    dispatch(setErrorMessage(error));
  };

  getRequest({
    url: `home_page/${slug}`,
    success,
    setError,
    params,
  });
};
export const getHomeInitiationSection = (params) => (dispatch) => {
  dispatch(setLoadingInitiationSection(true));

  const success = (data) => {
    dispatch(successGetInitiationSection(data.data));
  };

  const setError = (error) => {
    dispatch(setErrorInitiationSection(error));
  };

  getRequest({
    url: `initiation`,
    success,
    setError,
    params,
  });
};


const initialState = {
  pagehomeData: {},
  status: null,
  error: null,
  getlanguageRequest: null,
  pageHomeInitiationSection: [],
  statusHomeInitiationSection: null,
  errorHomeInitiationSection: null,
};

export const home = createSlice({
  name: "homePageData",
  initialState,
  reducers: {
    setErrorMessage(state, action) {
      state.error = action.payload;
      state.status = false;
    },
    successGetPage(state, action) {
      state.pagehomeData = action.payload;
      state.status = false;
      state.error = null;
    },

    setLoadingStatus(state, action) {
      state.status = action.payload;
      state.statusHomeInitiationSection = null;
    },
    setErrorInitiationSection(state, action) {
      state.errorHomeInitiationSection = action.payload;
      state.statusHomeInitiationSection = false;
    },
    successGetInitiationSection(state, action) {
      state.pageHomeInitiationSection = action.payload;
      state.statusHomeInitiationSection = false;
      state.errorHomeInitiationSection = null;
    },

    setLoadingInitiationSection(state, action) {
      state.statusHomeInitiationSection = action.payload;
      state.errorHomeInitiationSection = null;
    },
    successGetlanguageRequest(state, action) {
      state.getlanguageRequest = action.payload;
    },
  },
});

const {
  setErrorMessage,
  successGetPage,
  setLoadingStatus,
  successGetlanguageRequest,
  setErrorInitiationSection,
  successGetInitiationSection,
  setLoadingInitiationSection,
} = home.actions;

export default home.reducer;
