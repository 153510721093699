import React from "react";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

//styles
import s from "./congratulations.module.scss";

//assets
import arrowRigthSmall from "../../../assets/icons/ganeral/arrowRigthSmall.svg";

//ui
import { Button, Container, Title } from "../index";

//global
import { path, pathFC } from "../../App/Routes/paths";

const Congratulations = ({ title, btnTitle, info, link, underButtonLink }) => {
  const { t } = useTranslation();

  const history = useHistory();

  const onButtonClick = () => {
    history.push(link);
  };

  return (
    <section className={s.section}>
      <Container>
        <div className={s.finished}>
          <Title fontSize={60} noUnderline>
            {title}
          </Title>
          <div className={s.finished__info}>{info}</div>
          <Button
            onClick={onButtonClick}
            secondary
            width={"250px"}
            sidePadding={0}
          >
            {btnTitle}
          </Button>
          {underButtonLink && (
            <Link to={pathFC(path.myData)} className={s.underButtonLink}>
              {t("generals.goToMyProfile")}
              <img src={arrowRigthSmall} alt="arrowRigthSmall" />
            </Link>
          )}
        </div>
      </Container>
    </section>
  );
};

Congratulations.propTypes = {
  title: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  btnTitle: PropTypes.string.isRequired,
};

export default Congratulations;
