import React from "react";
import classNames from "classnames";

//styles
import s from "../Textarea/textarea.module.scss";

//components
import StyledInputWidth from "../StyledInputWidth/StyledInputWidth";

const Textarea = ({
  isEmpty,
  width,
  marginTop,
  label,
  id,
  name,
  register,
  error,
  required,
  placeholder,
  className,
  width100,
}) => {
  return (
    <StyledInputWidth width={width} marginTop={marginTop}>
      {register ? (
        <div className={classNames(s.root, className)}>
          <label>
            {label}
            {required ? <span className={s.required}>*</span> : ""}
          </label>
          <textarea
            className={classNames({
              [s.fill]: isEmpty,
              [s.textarea]: width100,
            })}
            id={id}
            placeholder={placeholder}
            name={name}
            {...register(name)}
          />
          {!!error && <div className={s.error}>{error?.message}</div>}
        </div>
      ) : (
        <div className={classNames(s.root, className)}>
          <label htmlFor={label}>
            {label}
            {required ? "*" : ""}
          </label>
          <textarea
            className={classNames({ [s.fill]: isEmpty })}
            id={label}
            placeholder={placeholder}
            name={name}
          />
        </div>
      )}
    </StyledInputWidth>
  );
};

Textarea.propTypes = {};

export default Textarea;
