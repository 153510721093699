import React from "react";
import { useSelector } from "react-redux";

//styles
import s from "./title.module.scss";

//svg
import { TitleUnderlineSvg } from "../svgHtml/TitleUnderlineSvg";

const Title = ({
  children,
  fontSize,
  noUnderline,
  marginTop,
  width,

  textAlign,
  lgFontSize120,
}) => {
  const language = useSelector((state) => state.app.language);
  return (
    <div className={`${language === "en" ? s.rootFontOne : s.rootFontTwo}`}>
      <h1
        className={`${s.title} ${lgFontSize120 && s.lgFontSize120}`}
        style={{
          fontSize: fontSize,
          marginTop: marginTop,
          width: width,
          textAlign: textAlign,
        }}
      >
        {children}
      </h1>
      {!noUnderline ? <TitleUnderlineSvg noUnderline={noUnderline} /> : ""}
    </div>
  );
};

export default Title;
