import * as Yup from "yup";

import {
  checkIfFilesAreTooBig,
  checkIfFilesAreCorrectType,
} from "./validationFunctionForYup";

import { passwordRegExp } from "../../utils/forms/regExp";

export const validationFormSchema = (t, nameFields, bool) => {
  return Yup.object().shape({
    firstName:
      nameFields?.firstName &&
      Yup.string()
        .required(t("yupValidation.isRequired"))
        .min(
          3,
          `${t(`yupValidation.minSymbols`)} 3 ${t(`yupValidation.symbols`)}`
        )
        .max(
          190,
          `${t(`yupValidation.maxSymbols`)} 190 ${t(`yupValidation.symbolss`)}`
        )
        .matches(/^([^0-9]*)$/, t("yupValidation.notContainNumbers")),

    lastName:
      nameFields?.lastName &&
      Yup.string()
        .required(t("yupValidation.isRequired"))
        .min(
          3,
          `${t(`yupValidation.minSymbols`)} 3 ${t(`yupValidation.symbols`)}`
        )
        .max(
          190,
          `${t(`yupValidation.maxSymbols`)} 190 ${t(`yupValidation.symbolss`)}`
        )
        .matches(/^([^0-9]*)$/, t("yupValidation.notContainNumbers")),
    facebook:
      nameFields?.facebook &&
      Yup.string().max(
        600,
        `${t(`yupValidation.maxSymbols`)} 600 ${t(`yupValidation.symbolss`)}`
      ),
    linkedin:
      nameFields?.linkedin &&
      Yup.string().max(
        300,
        `${t(`yupValidation.maxSymbols`)} 300 ${t(`yupValidation.symbolss`)}`
      ),

    email:
      nameFields?.email &&
      Yup.string()
        .email(t("yupValidation.email"))
        .max(
          190,
          `${t(`yupValidation.maxSymbols`)} 190 ${t(`yupValidation.symbolss`)}`
        )
        .required(t("yupValidation.isRequired")),
    phoneNumber:
      nameFields?.phoneNumber &&
      Yup.string()
        .required(t("yupValidation.isRequired"))
        .max(
          190,
          `${t(`yupValidation.maxSymbols`)} 190 ${t(`yupValidation.symbolss`)}`
        ),
    companyName:
      nameFields?.companyName &&
      Yup.string()
        .required(t("yupValidation.isRequired"))
        .min(
          2,
          `${t(`yupValidation.minSymbols`)} 2 ${t(`yupValidation.symbols`)}`
        )
        .max(
          190,
          `${t(`yupValidation.maxSymbols`)} 190 ${t(`yupValidation.symbolss`)}`
        ),

    position:
      nameFields?.position &&
      Yup.string()
        .required(t("yupValidation.isRequired"))
        .min(
          2,
          `${t(`yupValidation.minSymbols`)} 2 ${t(`yupValidation.symbols`)}`
        )
        .max(
          190,
          `${t(`yupValidation.maxSymbols`)} 190 ${t(`yupValidation.symbolss`)}`
        ),

    contactPerson:
      nameFields?.contactPerson &&
      Yup.string()
        .required(t("yupValidation.isRequired"))
        .min(
          2,
          `${t(`yupValidation.minSymbols`)} 2 ${t(`yupValidation.symbols`)}`
        )
        .max(
          190,
          `${t(`yupValidation.maxSymbols`)} 190 ${t(`yupValidation.symbolss`)}`
        ),
    textarea:
      nameFields?.textarea &&
      Yup.string()
        .required(t("yupValidation.isRequired"))
        .min(
          2,
          `${t(`yupValidation.minSymbols`)} 2 ${t(`yupValidation.symbols`)}`
        )
        .max(
          800,
          `${t(`yupValidation.maxSymbols`)} 800 ${t(`yupValidation.symbolss`)}`
        ),

    achievements:
      nameFields?.achievements &&
      Yup.string().max(
        800,
        `${t(`yupValidation.maxSymbols`)} 800 ${t(`yupValidation.symbolss`)}`
      ),

    committee:
      nameFields?.committee &&
      Yup.string()
        .required(t("yupValidation.isRequired"))
        .min(
          2,
          `${t(`yupValidation.minSymbols`)} 2 ${t(`yupValidation.symbols`)}`
        )
        .max(
          190,
          `${t(`yupValidation.maxSymbols`)} 190 ${t(`yupValidation.symbolss`)}`
        ),

    currentBoardMemberships:
      nameFields?.currentBoardMemberships &&
      Yup.string().required(t("yupValidation.isRequired")),
    yourBoardExperience:
      nameFields?.yourBoardExperience &&
      Yup.string().required(t("yupValidation.isRequired")),
    city:
      nameFields?.city &&
      Yup.string()
        .required(t("yupValidation.isRequired"))
        .min(
          2,
          `${t(`yupValidation.minSymbols`)} 2 ${t(`yupValidation.symbols`)}`
        )
        .max(
          190,
          `${t(`yupValidation.maxSymbols`)} 190 ${t(`yupValidation.symbolss`)}`
        ),
    gengerSelect:
      nameFields?.gengerSelect &&
      Yup.string().required(t("yupValidation.isRequired")),
    countrySelect:
      nameFields?.countrySelect &&
      Yup.string().required(t("yupValidation.isRequired")),
    ageSelect:
      nameFields?.ageSelect &&
      Yup.string().required(t("yupValidation.isRequired")),
    myltiSelectIndustry:
      nameFields?.myltiSelectIndustry &&
      Yup.mixed().required(t("yupValidation.isRequired")),
    myltiSelectSkills:
      nameFields?.myltiSelectSkills &&
      Yup.mixed().required(t("yupValidation.isRequired")),

    myltiSelectFunctionalSpeciality:
      nameFields?.myltiSelectFunctionalSpeciality &&
      Yup.mixed().required(t("yupValidation.isRequired")),
    languagesSelect:
      nameFields?.languagesSelect &&
      bool?.lang &&
      Yup.string().required(t("yupValidation.isRequired")),
    levelSelect: 
      nameFields?.levelSelect &&
      bool?.lang &&
      Yup.string().required(t("yupValidation.isRequired")),
    sectorSelect:
      nameFields?.sectorSelect &&
      Yup.string().required(t("yupValidation.isRequired")),
    citySelect:
      nameFields?.citySelect &&
      Yup.string().required(t("yupValidation.isRequired")),
    startDateSelect:
      nameFields?.startDateSelect &&
      Yup.string().required(t("yupValidation.isRequired")),
    startYearSelect:
      nameFields?.startYearSelect &&
      Yup.string().required(t("yupValidation.isRequired")),
    endYearSelect:
      nameFields?.endYearSelect &&
      !bool?.iscurrentlyWork &&
      Yup.string().required(t("yupValidation.isRequired")),
    endDateSelect:
      nameFields?.endDateSelect &&
      !bool?.iscurrentlyWork &&
      Yup.string().required(t("yupValidation.isRequired")),

    institution:
      nameFields?.institution &&
      Yup.string()
        .required(t("yupValidation.isRequired"))
        .min(
          2,
          `${t(`yupValidation.minSymbols`)} 2 ${t(`yupValidation.symbols`)}`
        )
        .max(
          190,
          `${t(`yupValidation.maxSymbols`)} 190 ${t(`yupValidation.symbolss`)}`
        ),
    fieldStudy:
      nameFields?.fieldStudy &&
      Yup.string()
        .required(t("yupValidation.isRequired"))
        .min(
          2,
          `${t(`yupValidation.minSymbols`)} 2 ${t(`yupValidation.symbols`)}`
        )
        .max(
          190,
          `${t(`yupValidation.maxSymbols`)} 190 ${t(`yupValidation.symbolss`)}`
        ),
    degree:
      nameFields?.degree &&
      Yup.string()
        .required(t("yupValidation.isRequired"))
        .min(
          2,
          `${t(`yupValidation.minSymbols`)} 2 ${t(`yupValidation.symbols`)}`
        )
        .max(
          190,
          `${t(`yupValidation.maxSymbols`)} 190 ${t(`yupValidation.symbolss`)}`
        ),
    checkboxConfirm:
      nameFields?.checkboxConfirm &&
      Yup.bool().test("bool", "", function (value) {
        if (!value) {
          return this.createError({
            message: t("yupValidation.phoneTest"),
          });
        }
        return true;
      }),

    controlledMaskedInputNumberPhone:
      nameFields?.controlledMaskedInputNumberPhone &&
      Yup.string()
        .required(t("yupValidation.isRequired"))
        .test("test_length", "", function (value) {
          const clearTel = (tel) => tel.replace(/[^0-9]/g, "");
          const clearedTel = clearTel(value);

          if (value && clearedTel.length < 12) {
            return this.createError({
              message: t("yupValidation.phoneTest"),
            });
          }
          return true;
        }),

    textareaIndustry:
      nameFields?.textareaIndustry &&
      Yup.string()
        .required(t("yupValidation.isRequired"))
        .test("test_length", "", function (value) {
          return true;
        }),

    textareaFunctionalSpeciality:
      nameFields?.textareaFunctionalSpeciality &&
      Yup.mixed().required(t("yupValidation.isRequired")),

    textareaSkills:
      nameFields?.textareaSkills &&
      Yup.mixed().required(t("yupValidation.isRequired")),

    login:
      nameFields?.login && Yup.string().required(t("yupValidation.isRequired")),

    fullName:
      nameFields?.fullName &&
      Yup.string()
        .required(t("yupValidation.isRequired"))
        .min(
          3,
          `${t(`yupValidation.minSymbols`)} 3 ${t(`yupValidation.symbols`)}`
        ),

    passwordRegister:
      nameFields?.passwordRegister &&
      Yup.string()
        .required(t("yupValidation.isRequired"))
        .max(
          35,
          `${t(`yupValidation.maxSymbols`)} 35 ${t(`yupValidation.symbolss`)}`
        )
        .matches(
          passwordRegExp,
          `${t(`yupValidation.minSymbols`)} 6 ${t(`yupValidation.symbols`)}, ${t(`yupValidation.passwordOnly`)}`
        ),
    passwordRegisterConfirmation:
      nameFields?.passwordRegisterConfirmation &&
      Yup.string()
        .required(t("yupValidation.isRequired"))
        .max(
          35,
          `${t(`yupValidation.maxSymbols`)} 35 ${t(`yupValidation.symbolss`)} `
        )
        .matches(
          passwordRegExp,
          `${t(`yupValidation.minSymbols`)} 6 ${t(`yupValidation.symbols`)}, ${t(`yupValidation.passwordOnly`)}`
        )
        .test(
          "passwords-match",
          t("yupValidation.passwordsDontEqual"),
          function (value) {
            return this.parent.passwordRegister === value;
          }
        ),

    password:
      nameFields?.password &&
      Yup.string().required(t("yupValidation.isRequired")),
    fileDiplom:
      nameFields?.fileDiplom &&
      Yup.mixed()
        .test("is-big-file", "", function (files) {
          const allowedSize = 5;
          const valid = checkIfFilesAreTooBig(files, allowedSize);
          if (!valid) {
            return this.createError({
              message: `${t("yupValidation.overSizeFile")} ${allowedSize} ${t(
                "generals.mb"
              )}`,
            });
          }
          return true;
        })
        .test("is-correct-file", "", function (files) {
          const allowedTypes = ["application/pdf", "image/jpeg", "image/png"];
          const valid = checkIfFilesAreCorrectType(files, allowedTypes);
          if (!valid) {
            return this.createError({
              message: `${t("yupValidation.allowedTypes")} pdf, jpeg, png`,
            });
          }
          return true;
        }),
    profileImg:
      nameFields?.profileImg &&
      Yup.mixed()
        .required(t("yupValidation.profileImageRequired"))
        .test(" is-correct-file", "", function (files) {
          const allowedSize = 2;
          const valid = checkIfFilesAreTooBig(files, allowedSize);
          if (!valid) {
            return this.createError({
              message: `${t("yupValidation.overSizeFile")} ${allowedSize} ${t(
                "generals.mb"
              )}`,
            });
          }
          return true;
        })
        .test("is-correct-file", "", function (files) {
          const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
          const valid = checkIfFilesAreCorrectType(files, allowedTypes);
          if (!valid) {
            return this.createError({
              message: `${t("yupValidation.allowedTypes")} jpg, png`,
            });
          }
          return true;
        }),

    fileWork:
      nameFields?.fileWork &&
      Yup.mixed()

        .test("is-big-file", "", function (files) {
          const allowedSize = 10;
          const valid = checkIfFilesAreTooBig(files, allowedSize);
          if (!valid) {
            return this.createError({
              message: `${t("yupValidation.overSizeFile")} ${allowedSize} ${t(
                "generals.mb"
              )}`,
            });
          }
          return true;
        })
        .test("is-correct-file", "", function (files) {
          const allowedTypes = ["application/pdf", "image/jpeg", "image/png"];
          const valid = checkIfFilesAreCorrectType(files, allowedTypes);
          if (!valid) {
            return this.createError({
              message: `${t("yupValidation.allowedTypes")} pdf, jpeg, png`,
            });
          }
          return true;
        }),
    personalDataRadio:
      nameFields?.personalDataRadio &&
      Yup.mixed().required(t("yupValidation.isRequired")),
    agreeRecievingEmailINSEADRadio:
      nameFields?.agreeRecievingEmailINSEADRadio &&
      Yup.mixed().required(t("yupValidation.isRequired")),
    youhaveBoardExperienceBoardMemberships:
      nameFields?.youhaveBoardExperienceBoardMemberships &&
      Yup.string()
        .required(t("yupValidation.isRequired"))
        .min(
          2,
          `${t(`yupValidation.minSymbols`)} 2 ${t(`yupValidation.symbols`)}`
        )
        .max(
          800,
          `${t(`yupValidation.maxSymbols`)} 800 ${t(`yupValidation.symbolss`)}`
        ),
    youKnowWantsCreateBoard:
      nameFields?.youKnowWantsCreateBoard &&
      Yup.string()
        .required(t("yupValidation.isRequired"))
        .min(
          2,
          `${t(`yupValidation.minSymbols`)} 2 ${t(`yupValidation.symbols`)}`
        )
        .max(
          800,
          `${t(`yupValidation.maxSymbols`)} 800 ${t(`yupValidation.symbolss`)}`
        ),
  });
};
