import { createSlice } from "@reduxjs/toolkit";

import { getRequest } from "../../API/api";

export const getNedDatabasePersons = (params) => (dispatch) => {
  dispatch(setLoadingStatus());

  const success = (data) => {
    dispatch(successNedDatabasePersons(data.data));
    dispatch(successNedDatabasePersonsMeta(data.meta));
    dispatch(successNedDatabaseFilters(data.filters));
    dispatch(successGetlanguageRequest(params.lang));
    window.scrollTo(0, 0);
  };

  const setError = (error) => {
    dispatch(setErrorMessage(error));
  };

  getRequest({
    url: `ned_database`,
    success,
    setError,
    params,
  });
};

export const getNedDatabasePerson = (id, params) => (dispatch) => {
  dispatch(setLoadingStatusGetPerson());
  const success = (data) => {
    dispatch(successNedDatabasePerson(data.data));
  };

  const setError = (error) => {
    dispatch(setErrorMessageGetPerson(error));
  };

  getRequest({
    url: `ned_database/${id}`,
    success,
    setError,
    params,
  });
};

const initialState = {
  nedDatabasePersons: [],
  nedDatabasePersonsMeta: {},
  nedDatabasePersonsFilters: [],

  status: null,
  error: null,
  nedDatabasePerson: {},
  statusGetPerson: null,
  errorPerson: null,
  filtersObjForBack: null,
  getlanguageRequest: null,
};

export const nedDatabase = createSlice({
  name: "nedDatabase",
  initialState,
  reducers: {
    setErrorMessage(state, action) {
      state.error = action.payload;
      state.status = false;
    },
    successNedDatabasePersons(state, action) {
      state.nedDatabasePersons = action.payload;
      state.status = false;
      state.error = null;
    },
    successNedDatabaseFilters(state, action) {
      state.nedDatabasePersonsFilters = action.payload;
    },
    successNedDatabasePersonsMeta(state, action) {
      state.nedDatabasePersonsMeta = action.payload;
    },

    setLoadingStatus(state, action) {
      state.status = true;
      state.error = false;
      state.nedDatabasePerson = null;
    },
    successNedDatabasePerson(state, action) {
      state.nedDatabasePerson = action.payload;
      state.statusGetPerson = false;
      state.error = null;
    },
    setLoadingStatusGetPerson(state, action) {
      // state.statusGetPerson = action.payload;
      state.statusGetPerson = true;
      state.errorPerson = false;
    },
    setErrorMessageGetPerson(state, action) {
      state.errorPerson = action.payload;
      state.statusGetPerson = false;
    },
    setFiltersObjForBack(state, action) {
      state.filtersObjForBack = action.payload;
    },
    successGetlanguageRequest(state, action) {
      state.getlanguageRequest = action.payload;
    },
  },
});
export const {
  setErrorMessage,
  successNedDatabasePersons,
  successNedDatabasePersonsMeta,
  setLoadingStatus,
  setLoadingStatusGetPerson,
  successNedDatabasePerson,
  setErrorMessageGetPerson,
  successNedDatabaseFilters,
  setFiltersObjForBack,
  successGetlanguageRequest,
} = nedDatabase.actions;
export default nedDatabase.reducer;
