import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

//styles
import s from "./skill-element.module.scss";

//svg
import { CloseSvg2 } from "../svgHtml/CloseSvg2";

const SkillElement = ({
  text,
  disabled,
  onDeleteClick,
  readonly,
  primary,
  className,
}) => {
  return (
    <div
      className={classNames(
        s.root,
        {
          [s.disabled]: disabled,
          [s.active]: !disabled,
          [s.primary]: primary,
        },
        className
      )}
    >
      <span className={s.text}>{text}</span>
      {!readonly && (
        <span className={s.close} onClick={onDeleteClick}>
          <CloseSvg2 />
        </span>
      )}
    </div>
  );
};

SkillElement.propTypes = {
  onDeleteClick: PropTypes.func,
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
};

export default SkillElement;
