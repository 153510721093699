import { lazy } from "react";
import { path, pathFC } from "./paths";
const Home = lazy(() => import("../../pages/Home/Home"));
const Education = lazy(() => import("../../pages/Education/Education"));
const AlumniPage = lazy(() => import("../../pages/AlumniPage/AlumniPage"));
const NedDatabase = lazy(() => import("../../pages/NedDatabase/NedDatabase"));
const NedDatabaseItem = lazy(() =>
  import("../../pages/NedDatabaseItem/NedDatabaseItem")
);
const Services = lazy(() => import("../../pages/Services/Services"));
const OurBoard = lazy(() => import("../../pages/OurBoard/OurBoard"));
const OurTeam = lazy(() => import("../../pages/OurTeam/OurTeam"));
const News = lazy(() => import("../../pages/News/News"));
const NewsPrivate = lazy(() => import("../../pages/News/NewsPrivate"));
const NewsItem = lazy(() => import("../../pages/NewsItem/NewsItem"));
const NewsItemPrivate = lazy(() =>
  import("../../pages/NewsItem/NewsItemPrivate.jsx")
);
const ApplicationForm = lazy(() =>
  import("../../pages/ApplicationForm/ApplicationForm")
);

const Login = lazy(() => import("../../pages/MyProfile/Login/Login"));
const ResetPassword = lazy(() =>
  import("../../pages/MyProfile/ResetPassword/ResetPassword")
);
const MyData = lazy(() => import("../../pages/MyData/MyData"));

const StudyingMaterialsPage = lazy(() =>
  import(
    "../../pages/LearningMaterials/StudyingMaterials/StudyingMaterialsPage"
  )
);
const GraduateWork = lazy(() =>
  import("../../pages/LearningMaterials/GraduateWork/GraduateWork")
);
const PageForPrograms = lazy(() =>
  import("../../pages/PageForPrograms/PageForPrograms")
);

const Registration = lazy(() =>
  import("../../pages/MyProfile/Registration/Registration")
);

const UpdatePassword = lazy(() =>
  import("../../pages/MyProfile/UpdatePassword/UpdatePassword")
);

export const publicRoutes = [
  {
    path: pathFC(path?.home),
    component: Home,
  },
  {
    path: pathFC(path?.education),
    component: Education,
  },
  {
    path: pathFC(`${path.education}/:slugProgram`),
    component: PageForPrograms,
  },
  {
    path: pathFC(`${path.education}/:slugProgram${path?.applicationForm}`),
    component: ApplicationForm,
  },
  {
    path: pathFC(path?.alumni),
    component: AlumniPage,
  },
  {
    path: pathFC(path.nedDatabase),
    component: NedDatabase,
  },
  {
    path: pathFC(`${path.nedDatabase}/:id`),
    component: NedDatabaseItem,
  },
  {
    path: pathFC(path?.services),
    component: Services,
  },
  {
    path: pathFC(path?.ourBoard),
    component: OurBoard,
  },
  {
    path: pathFC(path?.ourTeam),
    component: OurTeam,
  },
  // {
  //   path: pathFC(path.news),
  //   component: News,
  // },

  // {
  //   path: pathFC(`${path.news}/:slugNews`),
  //   component: NewsItem,
  // },
  {
    path: pathFC(path?.login),
    component: Login,
  },
  {
    path: pathFC(path?.resetPassword),
    component: ResetPassword,
  },
  {
    path: pathFC(path?.registration),
    component: Registration,
  },
  {
    path: pathFC(path?.updatePassword),
    component: UpdatePassword,
  },
];

export const privateRoutes = [
  {
    path: pathFC(path.privateNews),
    component: NewsPrivate,
  },
  {
    path: pathFC(`${path.privateNews}/:slugNews`),
    component: NewsItemPrivate,
  },

  {
    path: pathFC(path?.myData),
    component: MyData,
  },
  {
    path: pathFC(path?.studyingMaterials),
    component: StudyingMaterialsPage,
  },
  {
    path: pathFC(path?.graduateWork),
    component: GraduateWork,
  },
];
