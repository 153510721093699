import React from "react";
import classNames from "classnames";

//styles
import s from "./information-text.module.scss";

//components
import FontSizeStyled from "./FontSizeStyled/FontSizeStyled";

const AdaptiveInformationText = ({
  children,
  className,
  width,
  marginTop,
  fontSize,
  lineHeight,
  icon,
  marginBottom
}) => {
  return (
    <FontSizeStyled fontSize={fontSize} lineHeight={lineHeight} style={{position: "relative", marginBottom: marginBottom }}>
      <div
        className={classNames(s.root, className)}
        style={{
          maxWidth: width,
          marginTop: marginTop,
          lineHeight: lineHeight,
        }}
        dangerouslySetInnerHTML={{
          __html: children,
        }}
      />
      {icon} 
    </FontSizeStyled>
  );
};

export default AdaptiveInformationText;
