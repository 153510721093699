import { createSlice } from "@reduxjs/toolkit";

import { postRequest } from "../../API/api";

const initialState = {
  dataProfileSection: null,
  idRecord: localStorage.getItem("idRecord") || null,
  status: false,
  errorPostProfileSection: false,
  dataProfileSectionUpdate: null,
  statusUpdate: false,
  errorPostProfileSectionUpdate: false,
};

let token = localStorage.getItem("access_token_UCGA");

export const postUpdateProfileSection = (path, params, obj) => (dispatch) => {
  dispatch(setLoadingStatusPostUpdate(true));
  const success = (data) => {
    const newData = {
      [`${path}`]: data,
    };
    dispatch(successPostUpdateProfileSection(newData));
    dispatch(setLoadingStatusPostUpdate(false));
  };

  const setError = (error) => {
    dispatch(setErrorPostUpdateProfileSection(error));
    dispatch(setLoadingStatusPostUpdate(false));
  };

  postRequest({
    url: `ned_db/${path}/update`,
    success,
    setError,
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    obj,
  });
};

export const postProfileSection = (path, params, obj, errorCb) => (dispatch) => {
  dispatch(setLoadingStatus(true));
  const success = (data) => {
    const newData = {
      [`${path}`]: data,
    };
    dispatch(successPostProfileSection(newData));
    dispatch(setLoadingStatus(false));
  };

  const setError = (error) => {
    dispatch(setErrorPostProfileSection(error));
    dispatch(setLoadingStatus(false));
    errorCb && errorCb(error?.response?.data)
  };

  postRequest({
    url: `ned_db/${path}`,
    success,
    setError,
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    obj,
  });
};

export const myProfileSectionPost = createSlice({
  name: "myProfileSectionPost",
  initialState,
  reducers: {
    setLoadingStatus(state, action) {
      state.status = action.payload;
      state.error = null;
    },
    successPostProfileSection(state, action) {
      state.dataProfileSection = action.payload;
      state.status = false;
      state.errorPostProfileSection = null;
    },
    setErrorPostProfileSection(state, action) {
      state.errorPostProfileSection = action.payload;
      state.status = false;
    },
    setIdRecord(state, action) {
      state.idRecord = action.payload;
    },
    setLoadingStatusPostUpdate(state, action) {
      state.statusUpdate = action.payload;
      state.errorUpdate = null;
      state.dataProfileSectionUpdate = null;
    },
    successPostUpdateProfileSection(state, action) {
      state.dataProfileSectionUpdate = action.payload;
      state.statusUpdate = false;
      state.errorPostProfileSectionUpdate = null;
    },
    setErrorPostUpdateProfileSection(state, action) {
      state.errorPostProfileSectionUpdate = action.payload;
      state.statusUpdate = false;
    },
  },
});
export const {
  setLoadingStatus,
  successPostProfileSection,
  setErrorPostProfileSection,
  setIdRecord,
  setLoadingStatusPostUpdate,
  successPostUpdateProfileSection,
  setErrorPostUpdateProfileSection,
} = myProfileSectionPost.actions;

export default myProfileSectionPost.reducer;
