import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

//db
import { mainLanguageData } from "./DB/languageData";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: mainLanguageData?.key,
    debug: true,
    detection: {
      order: ["queryString", "cookie", "localStorage"],
      caches: ["c"],
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
