import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

//styles
import s from "../common1080.module.scss";

//ui
import PopUpCover from "../PopUpCover/PopUpCover";
import { Button } from "../../index";
import Input from "../../Inputs/Input/Input";
import Textarea from "../../Inputs/Textarea/Textarea";
import AdaptiveTitle from "../../AdaptiveTitle/AdaptiveTitle";
import CustomMaskedInput from "../../Inputs/MaskedInput/MaskedInput";
import Error from "../../Error/Error";

//utils
import { validationFormSchema } from "../../../../utils/forms/validationFormSchema";

//global
import { path, pathFC } from "../../../App/Routes/paths";

//api
import { createLead } from "../../../../API/birixApi/createLead";

const SendOffer = ({ onCloseClick, info }) => {
  const { t } = useTranslation();

  const history = useHistory();

  const nameFields = {
    firstName: "firstName",
    lastName: "lastName",
    email: "email",
    // phoneNumber: "phoneNumber",
    textarea: "textarea",
    controlledMaskedInputNumberPhone: "controlledMaskedInputNumberPhone",
  };

  const [successRequestBitrix, setSuccessRequestBitrix] = useState();
  const [errorRequestBitrix, setErrorRequestBitrix] = useState(false);
  const [loadingRequestBitrix, setLoadingRequestBitrix] = useState(false);

  useEffect(() => {
    if (successRequestBitrix?.data?.result) {
      setSuccessRequestBitrix(null);
      history.push(pathFC(path.thanks));
    }
  }, [successRequestBitrix]);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(validationFormSchema(t, nameFields)),
    defaultValues: {
      controlledMaskedInputNumberPhone: "",
    },
  });

  const onSubmit = (data) => {
    const userInfo = {
      firstName: data?.firstName,
      lastName: data?.lastName,
      email: data?.email,
      phone: data?.controlledMaskedInputNumberPhone,
      comment: `
        - How did you find out about us?: ${data?.textarea} \n
      `,
    };

    createLead(
      userInfo,
      "sendOffer",
      info,
      setErrorRequestBitrix,
      setSuccessRequestBitrix,
      setLoadingRequestBitrix,
      null,
      null
    );
  };

  return (
    <PopUpCover
      onCloseClick={onCloseClick}
      secondaryBackground
      width={"1080px"}
    >
      {!errorRequestBitrix ? (
        <div className={s.root}>
          <AdaptiveTitle
            fontSize={{
              xl: "100px",
              mdMinus: "72px",
              smPlus: "62px",
              sm: "50px",
            }}
            underlineWidth={200}
          >
            {t("modal.titleModal.sendOffer")}
          </AdaptiveTitle>
          <form onSubmit={handleSubmit(onSubmit)} className={s.form}>
            <Input
              label={t("form.inputTitle.firstName")}
              width={{ xl: "calc(50% - 10px)", md: "100%" }}
              id={nameFields?.firstName}
              type="text"
              name={nameFields?.firstName}
              register={register}
              error={errors?.firstName}
              required={true}
            />
            <Input
              label={t("form.inputTitle.lastName")}
              width={{ xl: "calc(50% - 10px)", md: "100%" }}
              id={nameFields?.lastName}
              type="text"
              name={nameFields?.lastName}
              register={register}
              error={errors?.lastName}
              required={true}
            />
            <Controller
              render={({ field }) => (
                <CustomMaskedInput
                  {...field}
                  width={{ xl: "calc(50% - 10px)", md: "100%" }}
                  label={t("form.inputTitle.phoneNumber")}
                  required={true}
                  error={errors?.controlledMaskedInputNumberPhone}
                />
              )}
              name={nameFields?.controlledMaskedInputNumberPhone}
              control={control}
            />
            <Input
              label={t("form.inputTitle.email")}
              width={{ xl: "calc(50% - 10px)", md: "100%" }}
              id={nameFields?.email}
              type="email"
              name={nameFields?.email}
              register={register}
              error={errors?.email}
              required={true}
            />
            <Textarea
              width={{ xl: "100%" }}
              label={t("form.inputTitle.howDidYouFindOutAbout")}
              id={nameFields?.textarea}
              name={nameFields?.textarea}
              register={register}
              error={errors?.textarea}
              required={true}
            />
            <Button
              marginTop={40}
              sidePadding={47}
              secondary
              disabled={loadingRequestBitrix}
            >
              {t("form.btnTitle.sendRequest")}
            </Button>
          </form>
        </div>
      ) : (
        <Error
          dataError={errorRequestBitrix}
          errorRequestBitrixMessage={
            errorRequestBitrix?.response?.data?.error_description
          }
        />
      )}
    </PopUpCover>
  );
};

export default SendOffer;
