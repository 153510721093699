// the date format on which the function works parseDateDayMon : "02.11.2021 13:57"

//assets
import UkrainianSvg from "../../assets/icons/flagCountries/Ukraine.svg";
import EnglishSvg from "../../assets/icons/flagCountries/Englang.svg";
import GermanSvg from "../../assets/icons/flagCountries/Germany.svg";
import FranceSvg from "../../assets/icons/flagCountries/France.svg";
import RussiaSvg from "../../assets/icons/flagCountries/Russia.svg";
import PolandSvg from "../../assets/icons/flagCountries/Poland.svg";
import CzechSvg from "../../assets/icons/flagCountries/Czech.svg";
import IsraelSvg from "../../assets/icons/flagCountries/Israel.svg";
import SlovakiaSvg from "../../assets/icons/flagCountries/Slovakia.svg";
import SpainSvg from "../../assets/icons/flagCountries/Spain.svg";
import TurkeySvg from "../../assets/icons/flagCountries/Turkey.svg";
import ItalySvg from "../../assets/icons/flagCountries/Italy.svg";

//db
import { langEN } from "../../DB/translationEN";

export const getFlagsCountries = (t, language_name) => {
  if (
    // language_name.toLowerCase().includes(t("languages.english").toLowerCase())
    language_name.toLowerCase().includes(langEN.languages.english.toLowerCase())
  )
    return EnglishSvg;

  if (
    // language_name.toLowerCase().includes(t("languages.german").toLowerCase())
    language_name.toLowerCase().includes(langEN.languages.german.toLowerCase())
  )
    return GermanSvg;

  if (
    // language_name.toLowerCase().includes(t("languages.french").toLowerCase())
    language_name.toLowerCase().includes(langEN.languages.french.toLowerCase())
  )
    return FranceSvg;

  if (
    // language_name.toLowerCase().includes(t("languages.spanish").toLowerCase())
    language_name.toLowerCase().includes(langEN.languages.spanish.toLowerCase())
  )
    return SpainSvg;

  if (
    // language_name.toLowerCase().includes(t("languages.ukrainian").toLowerCase())
    language_name
      .toLowerCase()
      .includes(langEN.languages.ukrainian.toLowerCase())
  )
    return UkrainianSvg;

  if (
    // language_name.toLowerCase().includes(t("languages.russian").toLowerCase())
    language_name.toLowerCase().includes(langEN.languages.russian.toLowerCase())
  )
    return RussiaSvg;

  if (
    // language_name.toLowerCase().includes(t("languages.czech").toLowerCase())
    language_name.toLowerCase().includes(langEN.languages.czech.toLowerCase())
  )
    return CzechSvg;

  if (
    // language_name.toLowerCase().includes(t("languages.slovak").toLowerCase())
    language_name.toLowerCase().includes(langEN.languages.slovak.toLowerCase())
  )
    return SlovakiaSvg;
  if (
    // language_name.toLowerCase().includes(t("languages.polish").toLowerCase())
    language_name.toLowerCase().includes(langEN.languages.polish.toLowerCase())
  )
    return PolandSvg;

  if (
    // language_name.toLowerCase().includes(t("languages.turkish").toLowerCase())
    language_name.toLowerCase().includes(langEN.languages.turkish.toLowerCase())
  )
    return TurkeySvg;

  if (
    // language_name.toLowerCase().includes(t("languages.hebrew").toLowerCase())
    language_name.toLowerCase().includes(langEN.languages.hebrew.toLowerCase())
  )
    return IsraelSvg;

    if (
      // language_name.toLowerCase().includes(t("languages.hebrew").toLowerCase())
      language_name.toLowerCase().includes(langEN.languages.italian.toLowerCase())
    )
      return ItalySvg;
};
