export const generalsAlumniFilterData = [
  {
    id: 1,
    title: "All",
  },
  {
    id: 2,
    title: "BD 2019S",
  },
  {
    id: 3,
    title: "BD 2018A",
  },
  {
    id: 4,
    title: "BD 2018S",
  },
  {
    id: 5,
    title: "BD 2017A",
  },
];
