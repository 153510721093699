import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

//styles
import s from "./general-information.module.scss";

//ui
import { SkillElement, Title } from "../../../../ui";

//utils
import { getFlagsCountries } from "../../../../../utils/parse/getFlagsCountries";

//db
import { langEN } from "../../../../../DB/translationEN";

const GeneralInformation = ({ data }) => {
  const { t } = useTranslation();

  let flagCountry = null;
  return (
    <div className={s.root}>
      <div className={s.section}>
        {data?.first_name && (
          <Title>
            {data.first_name} {data?.last_name}
          </Title>
        )}

        <div className={classNames(s.section__about, s.section__items)}>
          {/* {data?.age && (
            <div className={s.section__aboutItem}>
              <div className={s.title}>
                {langEN.filter.age}
              </div>
              <div className={s.value}>
                {data.age}
                {langEN.generals.ages}
              </div>
            </div>
          )} */}
          {(data?.country || data?.city) && (
            <div className={s.section__aboutItem}>
              <div className={s.title}>
                {/* {t("footer.location")} */} {langEN.footer.location}
              </div>
              <div className={s.value}>
                {data.city ? `${data.city}, ` : ""}
                {data.country?.country_name}
              </div>
            </div>
          )}
        </div>
      </div>
      {data?.skills?.length > 0 && (
        <div className={classNames(s.section)}>
          <div className={s.title}>
            {/* {t("generals.skills")} */}
            {langEN.generals.skills}
          </div>
          <div className={classNames(s.section__items, s.section__skills)}>
            {data?.skills?.map((el, index) => (
              <div className={s.section__skillsItem} key={index}>
                <SkillElement text={el?.skill_name} readonly={true} />
              </div>
            ))}
          </div>
        </div>
      )}
      {data?.languages?.length > 0 && (
        <div className={classNames(s.section)}>
          <div className={s.title}>
            {/* {t("generals.languages")} */}
            {langEN.generals.languages}
          </div>
          <div className={s.languages}>
            {data?.languages.map((item, index) => {
              flagCountry = getFlagsCountries(t, item?.language_name);
              return (
                <div className={s.language} key={index}>
                  <div className={s.languageSvg}>
                    <img src={flagCountry} alt="flag" />
                  </div>
                  <div className={s.languageData}>
                    <div className={s.languageTitle}>{item?.language_name}</div>
                    <div className={s.languageLevel}>{item?.level_name}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default GeneralInformation;
