import React from "react";

import MaskedInput from "react-input-mask";

//styles
import s from "./input.module.scss";

//components
import StyledInputWidth from "../StyledInputWidth/StyledInputWidth";

const CustomMaskedInput = React.forwardRef(
  (
    { value, onChange, name, width, error, label, required, marginTop },
    ref
  ) => {
    return (
      <StyledInputWidth width={width} marginTop={marginTop}>
        <div className={s.root}>
          {label ? (
            <label htmlFor={label}>
              {label}
              {required ? <span className={s.required}>*</span> : ""}
            </label>
          ) : (
            ""
          )}
          <MaskedInput
            name={name}
            value={value}
            mask="+999 99 999 99 99"
            maskplaceholder={"99-99"}
            alwaysShowMask
            onChange={(e) => {
              e.persist();
              onChange(e.target.value);
            }}
          >
            {(inputProps) => <input {...inputProps} id={label} ref={ref} />}
          </MaskedInput>
          {!!error && <div className={s.error}>{error?.message}</div>}
        </div>
      </StyledInputWidth>
    );
  }
);

export default CustomMaskedInput;
