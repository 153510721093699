import { createSlice } from "@reduxjs/toolkit";

//api
import { getRequest } from "../../API/api";

let token = localStorage.getItem("access_token_UCGA");

export const getCourse = (slug, params) => (dispatch) => {
  dispatch(setLoadingStatus());
  const success = (data) => {
    dispatch(successGetCourse(data.data));
    dispatch(successGetlanguageRequest(params.lang));
  };
  const setError = (error) => {
    dispatch(setErrorGetCourse(error));
  };

  getRequest({
    url: `/course/${slug}`,
    success,
    setError,
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const initialState = {
  stage: 1,
  errorGetCourse: null,
  course: {},
  status: null,
  getlanguageRequest: null,
};

export const myData = createSlice({
  name: "myData",
  initialState,
  reducers: {
    setStage: (state, action) => {
      state.stage = action.payload;
    },
    setLoadingStatus(state, action) {
      state.status = true;
      state.errorGetCourse = null;
    },
    successGetCourse(state, action) {
      state.course = action.payload;
      state.status = false;
      state.errorGetCourse = null;
    },
    setErrorGetCourse(state, action) {
      state.errorGetCourse = action.payload;
      state.status = false;
    },
    successGetlanguageRequest(state, action) {
      state.getlanguageRequest = action.payload;
    },
  },
});

export const {
  setStage,
  setLoadingStatus,
  successGetCourse,
  setErrorGetCourse,
  successGetlanguageRequest,
} = myData.actions;

export default myData.reducer;
