import React from "react";
import { useTranslation } from "react-i18next";
// import PropTypes from "prop-types";

//styles
import s from "./slide.module.scss";

//ui
import TextA from "../TextA/TextA";
import MemberPopUp from "../PopUps/MemberPopUp/MemberPopUp";
import ShowModalOnClick from "../PopUps/ShowModalOnClick";

//API
import { baseURLSingle } from "../../../API/api";

const Slide = ({ item }) => {
  const { t } = useTranslation();

  return (
    <div className={s.item}>
      <div>
        {item?.icon && (
          <div className={s.rootImg}>
            <img
              src={item?.icon}
              alt={item?.name || `${item?.first_name} ${item?.last_name}`}
            />
          </div>
        )}
        {item?.img && (
          <div className={s.rootImg}>
            <img
              src={item?.img}
              alt={item?.name || `${item?.first_name} ${item?.last_name}`}
            />
          </div>
        )}

        {item?.name && <div className={s.rootTitle}>{item.name}</div>}
        {(item?.last_name || item?.first_name) && (
          <div
            className={s.rootTitle}
          >{t(item?.first_name)}<br></br>{t(item?.last_name)}</div>
        )}

        {item?.info && (
          <div
            className={s.rootDesc}
            dangerouslySetInnerHTML={{ __html: t(item.info) }}
          />
        )}

        <div>
          <ShowModalOnClick PopUp={MemberPopUp} info={item || null}>
            <div className={s.moreInformation}>{t("generals.moreInformation")}</div>
          </ShowModalOnClick>
        </div>
      </div>
    </div>
  );
};

// Slide.propTypes = {
//   name: PropTypes.string.isRequired,
//   img: PropTypes.string.isRequired,
//   info: PropTypes.string.isRequired,
// };

export default Slide;
