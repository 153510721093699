import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useSelector } from "react-redux";

//styles
import s from "./title.module.scss";

//components
import FontSizeStyled from "./FontSizeStyled/FontSizeStyled";

//svg
import { AdaptiveTitleUnderlineSvg } from "../svgHtml/AdaptiveTitleUnderlineSvg";

const AdaptiveTitle = ({
  children,
  fontSize,
  noUnderline,
  marginTop,
  marginBottom,
  width,
  widthUnderline,
  padding,
}) => {
  const language = useSelector((state) => state.app.language);

  return (
    <FontSizeStyled fontSize={fontSize} width={width}>
      <div
        className={`${language === "en" ? s.rootFontOne : s.rootFontTwo}`}
        style={{ marginBottom: marginBottom }}
      >
        <h1
          className={classNames(s.title, { [s.noUnderline]: noUnderline })}
          style={{ marginTop: marginTop, padding: padding }}
        >
          {children}
        </h1>
        {!noUnderline ? (
          <AdaptiveTitleUnderlineSvg width={widthUnderline} />
        ) : (
          ""
        )}
      </div>
    </FontSizeStyled>
  );
};

AdaptiveTitle.propTypes = {
  fontSize: PropTypes.object,
  noUnderline: PropTypes.bool,
  marginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.object]),
  underlineWidth: PropTypes.number,
};

export default AdaptiveTitle;
