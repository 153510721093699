import { createSlice } from "@reduxjs/toolkit";

import { getRequest } from "../../API/api";

export const getProgramsPage = (slug, params) => (dispatch) => {
  dispatch(setLoadingStatus(true));

  const success = (data) => {
    dispatch(successGetPage(data.data));
    dispatch(successGetSlugRequest(slug));
    dispatch(successGetlanguageRequest(params.lang));
  };

  const setError = (error) => {
    dispatch(setErrorMessage(error));
  };

  getRequest({
    url: `board_direction/${slug}`,
    success,
    setError,
    params,
  });
};

const initialState = {
  pageData: {},
  status: null,
  error: null,
  getSlugRequest: null,
  getlanguageRequest: null,
};

export const programsPage = createSlice({
  name: "programsPage",
  initialState,
  reducers: {
    setErrorMessage(state, action) {
      state.error = action.payload;
      state.status = false;
    },
    successGetPage(state, action) {
      state.pageData = action.payload;
      state.status = false;
      state.error = null;
    },

    setLoadingStatus(state, action) {
      state.status = action.payload;
      state.error = null;
    },
    successGetSlugRequest(state, action) {
      state.getSlugRequest = action.payload;
    },
    successGetlanguageRequest(state, action) {
      state.getlanguageRequest = action.payload;
    },
  },
});
const {
  setErrorMessage,
  successGetPage,
  setLoadingStatus,
  successGetSlugRequest,
  successGetlanguageRequest,
} = programsPage.actions;

export default programsPage.reducer;
