import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

//styles
import s from "./slider-arrow-buttons.module.scss";

//svg
import { SliderArrowButtonsSvg } from "../svgHtml/SliderArrowButtonsSvg";

const SliderArrowButtons = ({ disabled, onNextClick, onPrevClick, prev }) => {
  return (
    <div
      className={classNames(s.root, {
        [s.active]: !disabled,
        [s.disabled]: disabled,
      })}
    >
      <div className={classNames(s.arrow, s.prev)} onClick={onPrevClick}>
        <SliderArrowButtonsSvg />
      </div>
      <div className={s.arrow} onClick={onNextClick}>
        <SliderArrowButtonsSvg />
      </div>
    </div>
  );
};

SliderArrowButtons.propTypes = {
  disabled: PropTypes.bool,
  onPrevClick: PropTypes.func,
  onNextClick: PropTypes.func,
  prev: PropTypes.bool,
};

export default SliderArrowButtons;
