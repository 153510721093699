const breakpoints = {
  xl: "1440px",
  lg: "1024px",
  md: "768px",
  mobile: "600px",
  mdMinus: "576px",
  smPlus: "464px",
  sm: "320px",
};

export default breakpoints;
