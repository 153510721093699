import React, { Suspense } from "react"; // , { Suspense }
import ReactDOM from "react-dom";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { HelmetProvider } from "react-helmet-async";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import "./i18n";

import logo from "./assets/icons/ganeral/logo1_blue_hor-_1_.svg";

Sentry.init({
  dsn: "https://a23649578d784b44888ed8335c9acadc@o1081602.ingest.sentry.io/6089592",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

// const div = document.querySelector(".ytp-show-cards-title");
// div.removeChild();

ReactDOM.render(
  <React.StrictMode>
    <Suspense
      fallback={
        <div className="loader">
          <img src={logo} alt="logo" />
        </div>
      }
    >
      <HelmetProvider>
        <BrowserRouter>
          <Provider store={store}>
            <App />
          </Provider>
        </BrowserRouter>
      </HelmetProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
