import React from "react";
import { Route, Switch } from "react-router";
import { useTranslation } from "react-i18next";
import { ErrorBoundary } from "react-error-boundary";
import { useSelector } from "react-redux";

//components
import ErrorComponent from "../ErrorBoundaryComponent/ErrorBoundaryComponent";

//routes
import { publicRoutes, privateRoutes } from "./Routes/routes";
import { path, pathFC } from "./Routes/paths";

//pages
import NedDatabaseItem from "../pages/NedDatabaseItem/NedDatabaseItem";

//ui
import Congratulations from "../ui/Congratulations/Congratulations";

//peges
import NotFound from "../pages/NotFound/NotFound";

let token = localStorage.getItem("access_token_UCGA");

const AppRouter = () => {
  const { t } = useTranslation();

  const logError = (error, errorInfo) => {
    console.log({ error, errorInfo });
  };

  const isLoggin = useSelector((state) => state.myProfileLogin.isLoggin);

  return (
    <Switch>
      {publicRoutes.map(({ path, component: Component }) => {
        return (
          <Route exact key={path} path={path}>
            <ErrorBoundary
              FallbackComponent={ErrorComponent}
              onError={logError}
            >
              <Component />
            </ErrorBoundary>
          </Route>
        );
      })}

      {privateRoutes.map(({ path, component: Component }) => {
        return (
          <Route exact key={path} path={path}>
            <ErrorBoundary
              FallbackComponent={ErrorComponent}
              onError={logError}
            >
              <Component />
            </ErrorBoundary>
          </Route>
        );
      })}

      <Route path={pathFC(path.myProfile)}>
        <NedDatabaseItem showBackToProfile myProfile={true} />
      </Route>

      <Route path={pathFC(path.wellDone)}>
        <Congratulations
          link={pathFC(path.home)}
          info={`${t("wellDone.info")}!!`}
          title={`${t("thanks.title")}`}
          btnTitle={t("btnTitle.homePage")}
        />
      </Route>

      <Route path={pathFC(path.thanks)}>
        <Congratulations
          link={pathFC(path.home)}
          info={t("thanks.info")}
          title={t("thanks.title")}
          btnTitle={t("btnTitle.homePage")}
        />
      </Route>

      <Route path={pathFC(path.greatJob)}>
        <Congratulations
          link={pathFC(path.myProfile)}
          info={t("greatJobCongratulations.info")}
          title={t("greatJobCongratulations.title")}
          btnTitle={t("btnTitle.viewMyPublicProfile")}
          underButtonLink
        />
      </Route>
      <Route component={NotFound} />
    </Switch>
  );
};

export default AppRouter;
