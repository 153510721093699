import React, { useState } from "react";

//styles
import s from "./detailed-information.module.scss";

//ui
import { Button, Title } from "../../../../ui";
import DetailedInformationItem from "../../../../ui/DetailedInformationItem/DetailedInformationItem";

//db
import { langEN } from "../../../../../DB/translationEN";

const DetailedInformation = ({ data }) => {
  const startCountShowItemWorkExperience = 1;

  const countsShowItemWorkExperience = 2;

  const [showItemWorkExperience, setShowItemWorkExperience] = useState(
    startCountShowItemWorkExperience
  );

  const handleShowMore = (hideAll) => {
    if (!hideAll) {
      data?.work_experience?.length - countsShowItemWorkExperience >= 0
        ? setShowItemWorkExperience(
            (prev) => prev + countsShowItemWorkExperience
          )
        : setShowItemWorkExperience(data?.work_experience?.length);
    } else {
      setShowItemWorkExperience(startCountShowItemWorkExperience);
    }
  };

  const sortMassDate = (mass) => {
    if(!mass?.length) return [];
    const sortMass = mass?.slice()?.sort((a, b) => new Date(b.end_date) - new Date(a.end_date))

    const massIsCurrentlywork = []
    const massNotCurrentlywork = []
    sortMass.map(item => {
      item.still_working ? massIsCurrentlywork.push(item) : massNotCurrentlywork.push(item)
      return item;
    })
    return [...massIsCurrentlywork, ...massNotCurrentlywork]
  }
  
  return (
    <div className={s.root}>
      {data?.work_experience?.length > 0 && (
        <div>
          <div className={s.item}>
            <Title fontSize={50} underlineWidth={140}>
              {/* {t("generals.workExperience")} */}
              {langEN.generals.workExperience}
            </Title>
            {sortMassDate(data?.work_experience)?.map((data, index) => {
              return (
                index < showItemWorkExperience && (
                  <DetailedInformationItem
                    key={index}
                    position={data?.position}
                    data={data}
                  />
                )
              );
            })}
          </div>
          {showItemWorkExperience < data?.work_experience?.length ? (
            <Button
              fontSize={22}
              sidePadding={32}
              tertiary
              onClick={() => handleShowMore(false)}
            >
              {/* {t("generals.showOn")} */}
              {langEN.generals.showOn}{" "}
              {data?.work_experience?.length - showItemWorkExperience <
              countsShowItemWorkExperience
                ? data?.work_experience?.length - showItemWorkExperience
                : countsShowItemWorkExperience}{" "}
              {/* {t("generals.more")} */}
              {langEN.generals.more}
            </Button>
          ) : (
            startCountShowItemWorkExperience <
              data?.work_experience?.length && (
              <Button
                fontSize={22}
                sidePadding={32}
                tertiary
                onClick={() => handleShowMore(true)}
              >
                {/* {t("generals.hideAll")} */}
                {langEN.generals.hideAll}
              </Button>
            )
          )}
        </div>
      )}
      {data?.board_experience?.length > 0 && (
        <div className={s.item}>
          <Title fontSize={50} underlineWidth={140}>
            {/* {t("generals.boardExperience")} */}
            {langEN.generals.boardExperience}
          </Title>
          {sortMassDate(data?.board_experience)?.map((data, index) => (
            <DetailedInformationItem
              key={index}
              position={data?.position}
              data={data}
            />
          ))}
        </div>
      )}

      {data?.education?.length > 0 && (
        <div className={s.item}>
          <Title fontSize={50} underlineWidth={140}>
            {/* {t("generals.education")} */}
            {langEN.generals.education}
          </Title>
          {sortMassDate(data?.education)?.map((data, index) => (
            <DetailedInformationItem
              key={index}
              position={data?.institution_name}
              data={data}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default DetailedInformation;
