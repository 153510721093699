export const langEN = {
  footer: {
    contact: "Contact",
    followUs: "Follow Us",
    location: "Location",
    ucga: "UCGA is partly funded through the Multi-donor Account for Ukraine set up at the European Bank for Reconstruction and Development (EBRD) in 2014.",
    ourDonors: "Our Donors",
    address: "BC Mandarin Basseina St 6, 8th floor, Kyiv, 01004, Ukraine",
    readyCreateBoards: "Ready to create boards",
    doIt: "Do it",
  },
  generals: {
    moreInformation: "More information",
    learnMore: "Learn More",
    moreAlumni: "More Alumni",
    letsDoIt: "Let`s do it",
    needAdvisoryForCompany: "Need Advisory for a company",
    example: "Example",
    add: "Add",
    save: "Save",
    recommendations: "Recommendations",
    continueContacInfo: "and continue to Contact info",
    iConfirmIhaveLegal:
      "I confirm I have the legal right or permission to share this information publicly",
    male: "Male",
    female: "Female",
    genger: "Gender",
    country: "Country",
    city: "City",
    continueKnowledgeSkills: "and continue to Knowledge and Skills",
    addyourQualification: "Add your qualification",
    institution: "Institution",
    fieldStudy: "Field of study",
    degree: "Degree",
    chooseYear: "Choose Year",
    saveQualification: "Save Qualification",
    cancel: "Cancel",
    fieldsStudy: "Fields of Study",
    languages: "Languages",
    chooseLanguage: "Choose language",
    LevelLanguage: "Level of language",
    skills: "Skills",
    andContinueTo: "and continue to",
    studyingMaterials: "Studying materials",
    myData: "My Data",
    graduateWork: "Graduate work",
    backToProfile: "Back to profile",
    ages: "y.o",
    workExperience: "Work Experience",
    boardExperience: "Board Experience",
    education: "Education",
    showOn: "Show",
    more: "more",
    hideAll: "Hide all",
    diploma: "diploma",
    alumnus: "Alumnus",
    certificate: "Certificate",
    social: "Social",
    sector: "Sector",
    industry: "Industry",
    close: "Close",
    experience: "Experience",
    goToMyProfile: "Go to my profile",
    profile: "Profile",
    providesSupport:
      "  Provides support and performs a role of facilitator between you and professional advisors in corporate governance.",
    password: "Password",
    rememberMe: "Remember me",
    commitee: "Committee",
    industries: "Industries",
    currentlyWork: "Currently work",
    addYourPosition: "Add your position",
    qualification: " Qualification",
    meterials: "Meterials",
    uploadWork: "Upload work",
    program: "Program",
    about: "Аbout",
    functionalSpeciality: "Functional Speciality",
    show: "Show",
    hide: "Hide",
    yes: "Yes",
    no: "No",
    mb: "mb",
    noData: "No data",
    noItems: "No more items",
    singOut: "Sign Out",
    registration: "Registration",
    register: "to register",
    fullName: "Full Name",
    yourPassword: "Your password",
    passwordConfirmation: "Repeat the password",
    nothingFound: "Nothing found",
    module: "Module",
    files: "Files",
    verification: "Verification by Prof.",
    description: "Description",
  },
  nedDatabase: {
    title: "NED Database",
    subtitle:
      "Online-service for professional networking and board member search",
    inputPlaceholderSearch: "Who are you looking for?",
  },
  filter: {
    filters: "Filters",
    reset: "Reset",
    filtered: "Filtered",
    age: "Age",
    sortbydateAddedNewOld: "Sort by date added new-old",
    sortbydateAddedOldNew: "Sort by date added old-new",
    sortbydateAddedAZ: "Sort by name A-z",
    sortbydateAddedZA: "Sort by name Z-a",
  },
  byProfessor: "by Prof.",
  proffesors: {
    one: "By Proffessor",
    more: "By Proffessors",
  },
  months: {
    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "October",
    november: "November",
    december: "December",
  },
  errors: {
    notFound: "Not Found",
    error404: "Error 404",
  },
  btn: {
    forForm: {
      applicationForm: "Application Form",
      needCustomRecommendations: "Need Custom Recommendations",
    },
  },
  form: {
    formTitle: {
      generalInfo: "General Info",
      boardExperience: "Board Experience",
    },
    inputTitle: {
      firstName: "First Name",
      lastName: "Last Name",
      email: "Email",
      phoneNumber: "Phone number",
      companyName: "Company name",
      position: "Position",
      contactPerson: "Contact person",
      howDidYouFindOutAbout: "How did you find out about us?",
      yourBoardExperience: "Your Board Experience",
      currentBoardMemberships: "Current Board Memberships",
      committee: "Committee",
      describeYourNeed: "Describe your need",
      startDate: "Start Date",
      startYear: "Start Year",
      endDate: "End Date",
      endYear: "End Year",
      whyInterestedInProgramWhatHisFinalGoal:
        "Why are you interested in this program? What is the final goal?",
      doYouConsentProcessingPersonalData:
        "Do you consent to the processing of personal data?",
      doYouAgreeRecievingEmailPartnerINSEAD:
        "Do you agree on recieving an e-mail newsletter with new programs from our partner INSEAD?",
      youhaveBoardExperienceBoardMemberships:
        "Do you have Board Experience or current Board Memberships?",
      youKnowWantsCreateBoard:
        "Do you know someone who wants to create a Board or be a member of the Board?",
    },
    btnTitle: {
      continue: "Continue",
      finish: "Finish",
      sendRequest: "Send request",
    },
    fieldsRequired: "Fields are required",
    placeholder: {
      yourEmail: "Your Email",
    },
  },
  to: "to",
  thanks: {
    title: "Thanks!",
    info: "Our team will call you back",
  },
  btnTitle: {
    homePage: "Home Page",
    viewMyPublicProfile: "view my public profile",
  },
  modal: {
    titleModal: {
      sendOffer: "Send Offer",
      policy: "Policy",
      advisory: "Advisory",
      youHaveNotFinishedPopUp: "You haven't finished",
      wouldYouLikeSaveData: "Would you like save data",
      successRegisterPopUp:
        "Congratulations you have successfully registered come on click on the button to go to the login page",
      successResetPasswordPopUp: "Please check your mailbox for the instructions on resetting the password"
    },
  },
  logIn: "Log In",
  lostPassword: "Lost password",
  forgot: "Forgot",
  forgoten: "Forgotten",
  yourPassword: "your password",
  resetPasswordInfo:
    "Don't worry, we'll send you a message to help you reset your password.",
  restore: "Restore",
  myData: {
    basicInfo: "Basic info",
    contactInfo: "Contact info",
    knowledgeAndSkills: "Knowledge and Skills",
    background: "Background",
    workExperience: "Work Experience",
    education: "Education",
    boardExperience: "Board Experience",
    organizationsAndAchievement: "Organizations and Achievement",
    notices:
    "<p>Please pay attention that the form can be filled in only in English. Before you get access to study materials, you should fill in at least five first steps.<br/> After graduating from UCGA, you can join NED Database with your profile. So try to describe all your experience and advantages.<p>",
    recarr: [
      "a photo that is at least 500x500 pixels, and no larger 2 mb",
      "portrait photo, full face, front view",
      "do not wear a hat or headgear that obscures the hair or hair line.",
      "dark glasses or nonprescription glasses with tinted lenses are not acceptable unless you need them for medical reasons.",
    ],
  },
  skills: {
    title: "Skills add value your person. You can separate skills using commas",
    list: [
      "Public Speaking",
      "Business Performance Improvment",
      "Creative problem-solving",
      "Strategy formulation",
    ],
  },
  organizationsAndAchievement: {
    achievements: "Achievements",
    organizations: "Organizations",
    organizationsHelper: {
      title:
        "We would like you to mention if you are a member of any organization or club.",
      list: [" UCGA Alumni", "Ukrainian Leadership Academy"],
    },
    achievementsHelper: {
      title:
        "Your Achievements have a good influence on the choice of the seeker.",
      list: [
        "National Foreign Language Olympiad Winner",
        "TACIS Project Winner (1995)",
        "PRINCE2 certified Practitioner",
      ],
    },
  },
  educationSubtitle:
    " World class education for current/future board members of private, public or state-owned companies",
  wellDone: {
    title: "Well done",
    info: "Your request is accepted. We will call you. If you have questions contact us",
  },
  greatJobCongratulations: {
    title: "Great Job",
    info: "Your account has been created successfully",
  },
  myProfileLink: {
    title: "My public profile",
    info: "Check out your profile",
  },
  descriptionArr: [
    "First read syllabus to understand who he is, what will be discussed and what you will learn new. There is also a note that there are must readings that must be read without fail (they are indicated by the letter (R), there are optional readings (they marked with the letter (O), which can be read after the module or before, if there is time and desire. ",
    "ALL materials in folders are arranged in chronological order as they go to syllabus. Read everything in the order in which they are arranged and numbered - you can't go wrong.",
    "Pay more attention to the Deutche Borse case. You will be working on it for most of Saturday. You will have a board simulation, each has its own role. Prepare that the board you will be on offers the most original way out of the situation.",
  ],
  add: {
    language: "Language",
    achievement: "Achievement",
    position: "Position",
    qualification: "Qualification",
  },
  dragPhoto: {
    text: ["Drag your", "browse", "to upload", "here or"],
    diplomaOrCertificate: "Diploma or Certificate",
    profileImage: "profile image",
    work: "work",
    rec: " Recommendations: no larger 5 mb, only pdf, png, jpg",
  },

  helper: {
    industry: [
      "Technology",
      "Legal Services",
      "Prof. Services",
      "Healthcare",
      "Media",
    ],
    functionalSpeciality: [
      "Marketing",
      "Human Capital / Talent Management",
      "General Management",
    ],
    about: {
      title: "Write what you did and what responsibilities you had",
      text: "Kyiv-Mohyla Business School (kmbs) is a business school in Ukraine. Professor of Marketing, delivered lectures and advised entrepreneurs on business management, strategy, marketing and innovation.",
    },
  },
  languages: {
    english: "English",
    german: "German",
    french: "French",
    spanish: "Spanish",
    ukrainian: "Ukrainian",
    russian: "Russian",
    czech: "Czech",
    slovak: "Slovak",
    polish: "Polish",
    turkish: "Turkish",
    hebrew: "Hebrew",
    italian: "Italian",
  },
  parseDateGetdiffDate: {
    year: "Year",
    years1: "Years",
    years2: "Years",
    month: "Month",
    months1: "Months",
    months2: "Months",
    day: "day",
    days1: "Days",
    days2: "Days",
  },
  notValidProfileLogin: "Invalid email or password",
  searchByWord: "Enter what you are looking for"
};
