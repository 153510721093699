export const pathPostSectionData = () => {
  return {
    basicInfo: "basic_info",
    contactInfo: "contact_info",
    knowledgeInfo: "knowledge_info",
    backgroundInfo: "background_info",
    educationInfo: "education_info",
    boardInfo: "board_info",
    organizationsInfo: "organizations_info",
  };
};
