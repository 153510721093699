import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ScrollToTop from "./ScrollToTop";
//styles
import "reset-css";
import "./index.scss";

import "swiper/swiper.scss"; // core Swiper
// import "swiper/css";
// import "swiper/css/free-mode";
// import "swiper/css/navigation";
// import "swiper/css/thumbs";

//vasja
//ui
import { Footer, Header } from "./components/ui";

import AppRouter from "./components/App/AppRouter";
import { setLanguage } from "./redux/App/app";
import { getSlugUser } from "./redux/MyProfile/myProfile";

//utils
import { findUrlLocalization } from "./utils/findUrlLocalization";
import { languageInitialization } from "./utils/languageInitialization";
import { useState } from "react";

const App = () => {
  const dispatch = useDispatch();

  const [, i18n] = useTranslation();
  const history = useHistory();

  const location = useLocation();

  const token = localStorage.getItem("access_token_UCGA");

  useEffect(() => {
    detectLanguage();
  }, []);

  const pathSplit = location.pathname.split("/");
  const isLocalizationUrl = findUrlLocalization(pathSplit);

  const { slugUser } = useSelector((state) => state.myProfile);

  const [isReadyRender, setIsReadyRender] = useState(false);
  const detectLanguage = () => {
    const language = localStorage.getItem("language_UCGA");
    changeLanguageApp(language || isLocalizationUrl || "en");
  };

  const changeLanguageApp = (language) => {
    dispatch(setLanguage(language));
    i18n.changeLanguage(language);
    const ready = languageInitialization(language, location, history);

    document.documentElement.lang = language ? language : "en";

    setIsReadyRender(() => ready);
  };
  useEffect(() => {
    if (!slugUser && token) {
      dispatch(getSlugUser());
    }
  }, []);

  if (!isReadyRender) return <></>;
  return (
    <>
      <Header />
      <ScrollToTop />
      <AppRouter />
      <Footer />
    </>
  );
};

export default App;
