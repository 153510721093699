import React from "react";

export const SliderArrowButtonsSvg = ({ width, height }) => (
  <svg
    width={width ? width : "13"}
    height={height ? height : "24"}
    viewBox={`0 0 ${width ? width : "13"} ${height ? height : "24"}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 10.5C10.1716 10.5 9.5 11.1716 9.5 12C9.5 12.8284 10.1716 13.5 11 13.5V10.5ZM12.5607 13.0607C13.1464 12.4749 13.1464 11.5251 12.5607 10.9393L3.01472 1.3934C2.42893 0.807611 1.47919 0.807611 0.893398 1.3934C0.307611 1.97919 0.307611 2.92893 0.893398 3.51472L9.37868 12L0.893398 20.4853C0.307611 21.0711 0.307611 22.0208 0.893398 22.6066C1.47919 23.1924 2.42893 23.1924 3.01472 22.6066L12.5607 13.0607ZM11 13.5H11.5V10.5H11V13.5Z"
      fill="#09354F"
    />
  </svg>
);
