import { path, pathFC } from "../components/App/Routes/paths";

export const headerNavigationData = [
  {
    id: 1,
    title: "header.navigation.home",
    link: pathFC(path.home),
    exact: true,
    isPrivate: false,
  },
  {
    id: 2,
    link: pathFC(path.education),
    dropdownTitle: "header.navigation.education",
    dropdown: [],
    isPrivate: false,
  },
  {
    id: 3,
    title: "header.navigation.nedDatabase",
    link: pathFC(path.nedDatabase),
    isPrivate: false,
  },
  {
    id: 4,
    title: "header.navigation.services",
    link: pathFC(path.services),
    isPrivate: false,
  },
  {
    id: 5,
    title: "header.navigation.ourBoard",
    link: pathFC(path.ourBoard),
    isPrivate: false,
  },
  {
    id: 6,
    title: "header.navigation.ourTeam",
    link: pathFC(path.ourTeam),
    isPrivate: false,
  },
  // {
  //   id: 7,
  //   title: "header.navigation.news",
  //   link: pathFC(path.news),
  //   isPrivate: false,
  // },
  {
    id: 8,
    title: "header.navigation.myData",
    link: pathFC(path.myData),
    isAdaptive: true,
    isLine: true,
    isPrivate: true,
  },
  {
    id: 9,
    title: "header.navigation.studyingMaterials",
    link: pathFC(path.studyingMaterials),
    isAdaptive: true,
    isPrivate: true,
  },
  {
    id: 10,
    title: "header.navigation.graduateWork",
    link: pathFC(path.graduateWork),
    isAdaptive: true,
    isPrivate: true,
  },
  {
    id: 12,
    title: "header.navigation.privateNews",
    link: pathFC(path.privateNews),
    isAdaptive: true,
    isPrivate: true,
  },
  {
    id: 11,
    title: "header.navigation.myProfile",
    link: pathFC(path.myData),
    btn: true,
  },

  {
    id: 13,
    title: "logIn",
    link: pathFC(path.login),
    btn: true,
    isPrivate: false,
  },
  {
    id: 14,
    title: "Sign Out",
    link: pathFC(path.home),
    btn: true,
    isPrivate: true,
  },
];
