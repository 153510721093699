import React from "react";
import classNames from "classnames";

//styles
import s from "./container.module.scss";

const Container = ({ children, notPadding }) => {
  return (
    <div
      className={classNames(s.root)}
      style={{ padding: `${notPadding && 0}` }}
    >
      {children}
    </div>
  );
};

export default Container;
