import { createSlice } from "@reduxjs/toolkit";

import { getRequest } from "../../API/api";

export const getOurBoardPersons = (params) => (dispatch) => {
  dispatch(setLoadingStatus(true));

  const success = (data) => {
    dispatch(successGetPersons(data.data));
    dispatch(successGetlanguageRequest(params.lang));
  };

  const setError = (error) => {
    dispatch(setErrorMessage(error));
  };

  getRequest({
    url: `employee`,
    success,
    setError,
    params,
  });
};

const initialState = {
  persons: [],
  status: null,
  error: null,
  getlanguageRequest: null,
};

export const ourBoard = createSlice({
  name: "ourBoard",
  initialState,
  reducers: {
    setErrorMessage(state, action) {
      state.error = action.payload;
      state.status = false;
    },
    successGetPersons(state, action) {
      state.persons = action.payload;
      state.status = false;
      state.error = null;
    },

    setLoadingStatus(state, action) {
      state.status = action.payload;
      state.error = null;
    },
    successGetlanguageRequest(state, action) {
      state.getlanguageRequest = action.payload;
    },
  },
});

const {
  setErrorMessage,
  successGetPersons,
  setLoadingStatus,
  successGetlanguageRequest,
} = ourBoard.actions;

export default ourBoard.reducer;
