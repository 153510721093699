import { createSlice } from "@reduxjs/toolkit";

//api
import { getRequest } from "../../API/api";

//db
import { pathPostSectionData } from "../../DB/profile";

const initialState = {
  dataFormsMyProfileSection: null,
  status: false,
  errorGetProfileSection: false,
};

export const getProfileSection = (path, params) => (dispatch) => {
  dispatch(setLoadingStatus());
  const success = (data) => {
    let moreDateInArray = null;
    switch (path) {
      case pathPostSectionData().backgroundInfo:
        moreDateInArray = "data";
        break;
      case pathPostSectionData().educationInfo:
        moreDateInArray = "education";
        break;
      case pathPostSectionData().boardInfo:
        moreDateInArray = "data";
        break;
      default:
        break;
    }
    if (moreDateInArray) {
      dispatch(successGetProfileSection(data[moreDateInArray]));
    } else {
      dispatch(successGetProfileSection(data));
    }
  };

  let token = localStorage.getItem("access_token_UCGA");

  const setError = (error) => {
    dispatch(setErrorGetProfileSection(error));
  };

  getRequest({
    url: `ned_db/${path}`,
    success,
    setError,
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const myProfileSectionGet = createSlice({
  name: "myProfileSectionGet",
  initialState,
  reducers: {
    setLoadingStatus(state, action) {
      state.status = true;
      state.error = null;
      state.dataFormsMyProfileSection = null;
    },
    successGetProfileSection(state, action) {
      state.dataFormsMyProfileSection = action.payload;
      state.status = false;
      state.errorGetProfileSection = null;
    },
    setErrorGetProfileSection(state, action) {
      state.errorGetProfileSection = action.payload;
      state.status = false;
    },
  },
});
export const {
  setLoadingStatus,
  successGetProfileSection,
  setErrorGetProfileSection,
} = myProfileSectionGet.actions;

export default myProfileSectionGet.reducer;
