import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

//styles
import s from "./footer.module.scss";
import Container from "../../Containers/Container/Container";

//svg
import { donorsArray } from "../../../svgArray";
import logo from "../../../../assets/icons/ganeral/logo1_blue_hor-_1_.svg";

//global
import { path, pathFC } from "../../../App/Routes/paths";

//DB
import {
  contactFooterOldData,
  socialFooterData,
} from "../../../../DB/footerData";

//svg
import { FooterDonorsSvg } from "../../svgHtml/FooterDonorsSvg";
import { SysaleLogoSvg } from "../../svgHtml/SysaleLogoSvg";
//text!

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className={s.footer}>
      <div className={s.info}>
        <Container>
          <div className={s.infoInnerWrapper}>
            <div className={s.infoInner}>
              <Link to={pathFC(path.home)} className={s.info__logo}>
                <img src={logo} alt="logo" />
              </Link>
              <div className={s.description}>
                <div className={s.wrapperInfoContact}>
                  <div className={s.info__title}>
                    {t("footer.readyCreateBoards")}?
                  </div>
                  <span>{t("footer.doIt")}!</span>
                  {contactFooterOldData && (
                    <a
                      href={contactFooterOldData?.url}
                      className={`${s.contactFooterData_link}`}
                      target={contactFooterOldData?.blank ? "_blank" : "_self"}
                      rel="noreferrer"
                    >
                      {contactFooterOldData?.title}
                    </a>
                  )}
                </div>
                <div className={s.info__title}>
                  {t("footer.followUs")}
                  <ul className={s.info__ulSocial}>
                    {socialFooterData?.map((item) => (
                      <li key={item.id}>
                        <a href={item.url} target="_blank" rel="noreferrer">
                          <div
                            className={s.svgSocial}
                            dangerouslySetInnerHTML={{ __html: item.svg }}
                          />
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {/* <div className={s.donors}>
        <div className={s.donors__info}>
          <div className={s.donorsSvg}>
            <FooterDonorsSvg />
          </div>
          <div>{t("footer.ucga")}</div>
        </div>
        <div className={s.donors__flags}>
          <span className={s.donors__text}>{t("footer.ourDonors")}:</span>
          <div className={s.donorsArray}>
            {donorsArray.map((el, index) => (
              <div
                key={index}
                className={s.svg}
                dangerouslySetInnerHTML={{ __html: el }}
              />
            ))}
          </div>
        </div>
      </div> */}
      {/* <div className={s.siteDevelop}>
        <a
          rel="noreferrer"
          target="_blank"
          href="https://sysale.ua/development/"
          className={s.siteDevelopText}
        >
          {t("siteDevelop")} -
        </a>
        <a rel="noreferrer" target="_blank" href="https://sysale.ua/">
         <SysaleLogoSvg />
        </a>
      </div> */}
    </footer>
  );
};

export default Footer;
