import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

//styles
import s from "./by-professor.module.scss";

//API
import { baseURLSingle } from "../../../API/api";

const ByProfessor = ({ className, name, disabled, did, icon, position }) => {
  return (
    <div
      className={classNames({ [className]: className }, [s.root], {
        [s.disabled]: disabled,
      })}
      disabled={disabled}
    >
      {/* {icon && (
        <div className={s.wrapperImage}>
          <img src={icon} alt="professor" className={s.image} />
        </div>
      )} */}
      {icon && (
        <img src={`${baseURLSingle}/${icon}`} alt={name} className={s.image} />
      )}
      <span className={s.text}>
        {did} by {position && `${position?.slice(0, 4)}.`} {name}
      </span>
    </div>
  );
};

ByProfessor.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default ByProfessor;
