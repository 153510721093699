import React from "react";

export const CloseSvg2 = ({ width, height, bold }) => (
  <svg
    width={width ? width : "10"}
    height={height ? height : "10"}
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="8.48529"
      y1="1.92905"
      x2="1.41422"
      y2="9.00011"
      stroke="#09354F"
      strokeWidth={bold ? bold : "2"}
      strokeLinecap="round"
    />
    <line
      x1="8.48514"
      y1="8.99921"
      x2="1.41407"
      y2="1.92814"
      stroke="#09354F"
      strokeWidth={bold ? bold : "2"}
      strokeLinecap="round"
    />
  </svg>
);
