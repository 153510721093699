import { createSlice } from "@reduxjs/toolkit";

import { getRequest } from "../../API/api";
import { newsFiltersData } from "../../DB/newsData";

export const getNews = (params) => (dispatch) => {
  dispatch(setLoadingNews());

  const success = (data) => {
    dispatch(successNews(data.data));
    dispatch(successNewsMeta(data.meta));
  };

  const setError = (error) => {
    dispatch(setErrorMessageNews(error));
  };

  getRequest({
    url: `news_page`,
    success,
    setError,
    params,
  });
};

export const getNewsItem = (slug, params) => (dispatch) => {
  dispatch(setLoadingNewsItem());

  const success = (data) => {
    dispatch(successNewsItem(data.data));
    // dispatch(successAlumniFilters(data.filters));
    // dispatch(successGetlanguageRequest(params.lang));
  };

  const setError = (error) => {
    dispatch(setErrorMessageNewsItem(error));
  };

  getRequest({
    url: `news_page/${slug}`,
    success,
    setError,
    params,
  });
};

const initialState = {
  news: [],
  newsFilters: newsFiltersData,

  statusNews: null,
  errorNews: null,
  newsItem: {},
  statusNewsItem: null,
  errorNewsItem: null,
  newsMeta: {},
};

export const news = createSlice({
  name: "news",
  initialState,
  reducers: {
    setLoadingNews(state, action) {
      state.statusNews = true;
      state.errorNews = false;
      state.alumniPersons = null;
    },
    setErrorMessageNews(state, action) {
      state.errorNews = action.payload;
      state.statusNews = false;
    },
    successNews(state, action) {
      state.news = action.payload;
      state.statusNews = false;
      state.errorNews = null;
    },
    successNewsMeta(state, action) {
      state.newsMeta = action.payload;
    },
    setLoadingNewsItem(state, action) {
      state.statusNewsItem = true;
      state.error = false;
      state.errorNewsItem = null;
    },
    setErrorMessageNewsItem(state, action) {
      state.errorNewsItem = action.payload;
      state.statusNewsItem = false;
    },
    successNewsItem(state, action) {
      state.newsItem = action.payload;
      state.statusNewsItem = false;
      state.errorNewsItem = null;
    },
  },
});

export const {
  setLoadingNews,
  setErrorMessageNews,
  successNews,
  successNewsMeta,
  setLoadingNewsItem,
  setErrorMessageNewsItem,
  successNewsItem,
} = news.actions;

export default news.reducer;
