import { createSlice } from "@reduxjs/toolkit";

import { deleteRequest } from "../../API/api";

const initialState = {
  dataDeleteProfileElement: null,
  status: false,
  errorDeleteProfileElement: false,
};

let token = localStorage.getItem("access_token_UCGA");

export const deleteProfileElementSection =
  (path, params, data) => (dispatch) => {
    dispatch(setLoadingStatus());
    const success = (data) => {
      const newData = {
        [`${path}`]: data,
      };
      dispatch(successDeleteProfileElement(newData));
    };

    const setError = (error) => {
      dispatch(setErrorDeleteProfileElement(error));
    };

    deleteRequest({
      url: `ned_db/${path}`,
      success,
      setError,
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  };

export const myProfileSectionPost = createSlice({
  name: "myProfileSectionPost",
  initialState,
  reducers: {
    setLoadingStatus(state) {
      state.status = true;
      state.errorDeleteProfileElement = null;
    },
    successDeleteProfileElement(state, action) {
      state.dataDeleteProfileElement = action.payload;
      state.status = false;
      state.errorDeleteProfileElement = null;
    },
    setErrorDeleteProfileElement(state, action) {
      state.errorDeleteProfileElement = action.payload;
      state.status = false;
    },
  },
});

export const {
  setLoadingStatus,
  successDeleteProfileElement,
  setErrorDeleteProfileElement,
} = myProfileSectionPost.actions;

export default myProfileSectionPost.reducer;
