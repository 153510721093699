import React from "react";
import classNames from "classnames";
import ReactDOM from "react-dom";

//styles
import s from "./pop-up-cover.module.scss";

//svg
import { CloseSvg } from "../../svgHtml/closeSvg";

const PopUpCover = ({
  children,
  onCloseClick,
  primaryBackground,
  secondaryBackground,
  width,
  fullSizeOnMobile,
}) => {
  React.useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "visible");
  }, []);

  return ReactDOM.createPortal(
    <div className={s.root}>
      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onCloseClick();
        }}
        className={classNames(s.background, {
          [s.primaryBackground]: primaryBackground,
          [s.secondaryBackground]: secondaryBackground,
          [s.fullSizeOnMobile]: fullSizeOnMobile,
        })}
      />
      <div
        className={classNames(s.container, {
          [s.fullSizeOnMobile]: fullSizeOnMobile,
        })}
        style={{ maxWidth: width }}
      >
        <div className={s.close} onClick={onCloseClick}>
          <CloseSvg width={"13"} height={"14"} />
        </div>
        {children}
      </div>
    </div>,
    document.getElementById("popup")
  );
};

export default PopUpCover;
