import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { NavLink } from "react-router-dom";

//styles
import s from "./header.module.scss";

//assets
import arrowForSelect from "../../../../assets/icons/ganeral/arrowForSelect.svg";

//global
import { path, pathFC } from "../../../App/Routes/paths";

export const Dropdown = ({ dropdownTitle, dropdown }) => {
  const { t } = useTranslation();

  const [hover, setHover] = useState(false);

  return (
    <div
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className={s.dropdownText}>
        <span className={s.text}>{t(dropdownTitle)}</span>
        <img
          src={arrowForSelect}
          alt="arrow"
          className={`${classNames({
            [s.reverse]: hover,
            [s.normal]: !hover,
          })} ${s.arrow}`}
        />
      </div>
      {hover ? (
        <div className={s.dropdownItems}>
          <div className={s.connect} />
          <ul className={s.dropdown}>
            {dropdown.length > 0 ? (
              dropdown.map((item) => {
                return (
                  <li key={item?.id} onClick={() => setHover(false)}>
                    <NavLink
                      to={pathFC(`${path.education}/${item?.slug}`)}
                      className={s.dropdownLink}
                    >
                      {item?.name}
                    </NavLink>
                  </li>
                );
              })
            ) : (
              <li className={s.activedrop}>{t("generals.nothingFound")} </li>
            )}
          </ul>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
