const positionCompanyOnMap = {
  lat: 50.441276185057596,
  lng: 30.52154985055448,
};

export const contactFooterData = [
  {
    id: 1,
    title: "+38 050 331 34 50",
    url: "tel:+380503313450",
    alt: "phone",
    tel: true,
  },
  {
    id: 2,
    title: "sbulavin@ucga.com.ua",
    url: "mailto:sbulavin@ucga.com.ua",
    alt: "mail",
  },
];

export const contactFooterOldData = {
  title: "+38 067 599 62 24",
  url: "tel:+380675996224",
  alt: "phone",
  tel: true,
};

export const addressFooterData = {
  title: "footer.address",
  url: `https://www.google.com/maps/dir/?api=1&destination=${positionCompanyOnMap.lat},${positionCompanyOnMap.lng}`,
};

export const socialFooterData = [
  {
    id: 1,
    svg: `
      <svg
        width="24"
        height="17"
        viewBox="0 0 24 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23.0501 2.55729C22.7788 1.55016 21.9824 0.758714 20.9689 0.48912C19.1319 0 11.7702 0 11.7702 0C11.7702 0 4.40849 0 2.57339 0.48912C1.55993 0.758714 0.763492 1.55016 0.4922 2.55729C0 4.3809 0 8.18795 0 8.18795C0 8.18795 0 11.995 0.4922 13.8186C0.763492 14.8257 1.55993 15.6172 2.57339 15.8868C4.40849 16.3759 11.7702 16.3759 11.7702 16.3759C11.7702 16.3759 19.1319 16.3759 20.967 15.8868C21.9804 15.6172 22.7769 14.8257 23.0481 13.8186C23.5403 11.995 23.5403 8.18795 23.5403 8.18795C23.5403 8.18795 23.5403 4.3809 23.0501 2.55729Z"
          fill="#8897a0"
        />
        <path
          d="M9.41406 11.6968L15.5317 8.18826L9.41406 4.67969V11.6968Z"
          fill="white"
        />
      </svg>
  `,
    url: "https://www.youtube.com/channel/UCUfOWm7Ismab6ztrW7hOzIg/featured",
  },
  {
    id: 2,
    svg: `
      <svg
        width="23"
        height="22"
        viewBox="0 0 23 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.2675 10.7002C22.2675 4.79107 17.4764 0 11.5673 0C5.65826 0 0.867188 4.79107 0.867188 10.7002C0.867188 16.0405 4.77949 20.4674 9.89559 21.2709V13.7939H7.17804V10.7002H9.89559V8.34224C9.89559 5.66081 11.4936 4.17864 13.9373 4.17864C15.108 4.17864 16.3328 4.3878 16.3328 4.3878V7.02109H14.9831C13.6544 7.02109 13.2391 7.84568 13.2391 8.69284V10.7002H16.2064L15.7324 13.7939H13.2391V21.2709C18.3552 20.4689 22.2675 16.042 22.2675 10.7002Z"
          fill="#8897a0"
        />
        <path
          d="M15.7321 13.794L16.2061 10.7002H13.2388V8.69291C13.2388 7.84725 13.6526 7.02115 14.9828 7.02115H16.3325V4.38787C16.3325 4.38787 15.1077 4.17871 13.937 4.17871C11.4933 4.17871 9.89528 5.65937 9.89528 8.34231V10.7002H7.17773V13.794H9.89528V21.271C10.44 21.3567 10.9983 21.4004 11.567 21.4004C12.1358 21.4004 12.6941 21.3552 13.2388 21.271V13.794H15.7321Z"
          fill="white"
        />
      </svg>
    `,
    url: "https://www.facebook.com/UkrainianCorporateGovernanceAcademy/",
  },
  {
    id: 3,
    svg: `
      <svg
        width="20"
        height="19"
        viewBox="0 0 20 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.1837 0H2.63153C1.63106 0 0.8125 0.818562 0.8125 1.81903V16.3712C0.8125 17.3717 1.63106 18.1903 2.63153 18.1903H17.1837C18.1842 18.1903 19.0028 17.3717 19.0028 16.3712V1.81903C19.0028 0.818562 18.1842 0 17.1837 0ZM6.26958 15.4617H3.54104V7.27611H6.26958V15.4617ZM4.90531 5.72993C3.9958 5.72993 3.26819 5.00232 3.26819 4.09281C3.26819 3.1833 3.9958 2.45569 4.90531 2.45569C5.81482 2.45569 6.54243 3.1833 6.54243 4.09281C6.54243 5.00232 5.81482 5.72993 4.90531 5.72993ZM16.2742 15.4617H13.5457V10.6413C13.5457 9.91373 12.909 9.27704 12.1814 9.27704C11.4538 9.27704 10.8171 9.91373 10.8171 10.6413V15.4617H8.08861V7.27611H10.8171V8.36752C11.2719 7.63991 12.2724 7.0942 13.0909 7.0942C14.819 7.0942 16.2742 8.54943 16.2742 10.2775V15.4617Z"
          fill="#8897a0"
        />
      </svg>
      `,
    url: "https://www.linkedin.com/company/ukrainian-corporate-governance-academy/",
  },
  {
    id: 4,
    svg: `
    <svg xmlns="http://www.w3.org/2000/svg"  width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M12,9.52A2.48,2.48,0,1,0,14.48,12,2.48,2.48,0,0,0,12,9.52Zm9.93-2.45a6.53,6.53,0,0,0-.42-2.26,4,4,0,0,0-2.32-2.32,6.53,6.53,0,0,0-2.26-.42C15.64,2,15.26,2,12,2s-3.64,0-4.93.07a6.53,6.53,0,0,0-2.26.42A4,4,0,0,0,2.49,4.81a6.53,6.53,0,0,0-.42,2.26C2,8.36,2,8.74,2,12s0,3.64.07,4.93a6.86,6.86,0,0,0,.42,2.27,3.94,3.94,0,0,0,.91,1.4,3.89,3.89,0,0,0,1.41.91,6.53,6.53,0,0,0,2.26.42C8.36,22,8.74,22,12,22s3.64,0,4.93-.07a6.53,6.53,0,0,0,2.26-.42,3.89,3.89,0,0,0,1.41-.91,3.94,3.94,0,0,0,.91-1.4,6.6,6.6,0,0,0,.42-2.27C22,15.64,22,15.26,22,12S22,8.36,21.93,7.07Zm-2.54,8A5.73,5.73,0,0,1,19,16.87,3.86,3.86,0,0,1,16.87,19a5.73,5.73,0,0,1-1.81.35c-.79,0-1,0-3.06,0s-2.27,0-3.06,0A5.73,5.73,0,0,1,7.13,19a3.51,3.51,0,0,1-1.31-.86A3.51,3.51,0,0,1,5,16.87a5.49,5.49,0,0,1-.34-1.81c0-.79,0-1,0-3.06s0-2.27,0-3.06A5.49,5.49,0,0,1,5,7.13a3.51,3.51,0,0,1,.86-1.31A3.59,3.59,0,0,1,7.13,5a5.73,5.73,0,0,1,1.81-.35h0c.79,0,1,0,3.06,0s2.27,0,3.06,0A5.73,5.73,0,0,1,16.87,5a3.51,3.51,0,0,1,1.31.86A3.51,3.51,0,0,1,19,7.13a5.73,5.73,0,0,1,.35,1.81c0,.79,0,1,0,3.06S19.42,14.27,19.39,15.06Zm-1.6-7.44a2.38,2.38,0,0,0-1.41-1.41A4,4,0,0,0,15,6c-.78,0-1,0-3,0s-2.22,0-3,0a4,4,0,0,0-1.38.26A2.38,2.38,0,0,0,6.21,7.62,4.27,4.27,0,0,0,6,9c0,.78,0,1,0,3s0,2.22,0,3a4.27,4.27,0,0,0,.26,1.38,2.38,2.38,0,0,0,1.41,1.41A4.27,4.27,0,0,0,9,18.05H9c.78,0,1,0,3,0s2.22,0,3,0a4,4,0,0,0,1.38-.26,2.38,2.38,0,0,0,1.41-1.41A4,4,0,0,0,18.05,15c0-.78,0-1,0-3s0-2.22,0-3A3.78,3.78,0,0,0,17.79,7.62ZM12,15.82A3.81,3.81,0,0,1,8.19,12h0A3.82,3.82,0,1,1,12,15.82Zm4-6.89a.9.9,0,0,1,0-1.79h0a.9.9,0,0,1,0,1.79Z"/>
    </svg>
      `,
    url: "https://www.instagram.com/ucgafamily/",
  },
];
