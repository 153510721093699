import { languageData, mainLanguageData } from "../DB/languageData";

export const languageInitialization = (
  language,
  currentLocation,
  currentHistory
) => {
  let finalPathname;

  const langKey = languageData.map((item) => `/${item.key}`);

  let withoutlang = `${currentLocation.pathname}${currentLocation?.search}`;
  
  langKey.map((lang) => {
    if (currentLocation.pathname.includes(lang)) {
      withoutlang = currentLocation.pathname.replace(lang, "");
    }
    return lang;
  });

  const isLanguageMain = mainLanguageData.key === language;
  finalPathname = isLanguageMain ? withoutlang : `/${language}${withoutlang}`;

  currentHistory.replace(finalPathname);

  return true;
};
