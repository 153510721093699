import React from "react";
import classNames from "classnames";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

//styles
import s from "./detailed-information-item.module.scss";

//svg
import { DiplomSvg2 } from "../../ui/svgHtml/DiplomSvg2";

//utils
import {
  parseDateDayMonYear,
  parseDateGetdiffDate,
} from "../../../utils/parse/parsedate";

//API
import { baseURLSingle } from "../../../API/api";

//db
import { langEN } from "../../../DB/translationEN";

const DetailedInformationItem = ({ position, data }) => {
  let date = null;
  const lg = useMediaQuery({ maxWidth: 1200 });
  const { t } = useTranslation();

  return (
    <div className={s.section}>
      <div className={s.section__data}>
        <div className={s.section__left}>
          <div className={s.left__top}>
            {position && (
              <div className={classNames(s.left__topTitle, s.title)}>
                {position}
              </div>
            )}

            {data?.level && (
              <div className={classNames(s.left__topValue, s.value)}>
                {data.level}
              </div>
            )}

            {data?.field_of_study && (
              <div className={classNames(s.left__topValue, s.value)}>
                {/* {t("generals.fieldStudy")}: */}
                {langEN.generals.fieldStudy}: {data.field_of_study}
              </div>
            )}

            {data?.company_name && (
              <div className={classNames(s.left__topValue, s.value)}>
                {data.company_name}
              </div>
            )}
          </div>

          {data?.commitie && (
            <div className={s.item}>
              <div className={s.value}>
                {/* {t("generals.commitee")}: */}
                {langEN.generals.commitee}:
              </div>
              <div className={s.title}>{data?.commitie}</div>
            </div>
          )}

          {data?.industries?.length > 0 && (
            <div className={s.item}>
              <div className={s.value}>
                {/* {t("generals.industries")}: */}
                {langEN.generals.industries}:
              </div>
              <div className={s.title}>
                {data?.industries.map((item, index) => {
                  return `${
                    data?.industries?.length === index + 1
                      ? `${item?.name}`
                      : `${item?.name}, `
                  }`;
                })}
              </div>
            </div>
          )}

          {data?.specialities?.length > 0 && (
            <div className={s.item}>
              <div className={s.value}>
                {/* {t("generals.functionalSpeciality")}: */}
                {langEN.generals.functionalSpeciality}:
              </div>
              <div className={s.title}>
                {data?.specialities.map((item, index) => {
                  return `${
                    data?.specialities?.length === index + 1
                      ? `${item?.name}`
                      : `${item?.name}, `
                  }`;
                })}
              </div>
            </div>
          )}

          {/* {data?.industry_name && (
            <div className={s.item}>
              <div className={s.value}>{t("generals.industry")}:</div>
              <div className={s.title}>{data?.industry_name}</div>
            </div>
          )} */}
          {data?.sector_name && (
            <div className={s.item}>
              <div className={s.value}>
                {/* {t("generals.sector")}: */}
                {langEN.generals.sector}:
              </div>
              <div className={s.title}>{data?.sector_name}</div>
            </div>
          )}
          {data?.about && (
            <div className={s.item}>
              <div className={s.value}>
                {/* {t("generals.about")}: */}
                {langEN.generals.about}:
              </div>
              <div className={s.title}>{data?.about}</div>
            </div>
          )}

          {lg && data?.diplom_file && (
            <div className={s.dimploma}>
              <DiplomSvg2 />
              <span className={s.dimploma__text}>
                {/* {t("generals.diploma")} */}
                {langEN.generals.diploma}
              </span>
            </div>
          )}
        </div>
        {!lg && (
          <div className={s.dimploma}>
            {data?.diplom_file && (
              <a
                className={s.dimploma}
                href={`${baseURLSingle}/${data?.diplom_file}`}
                download
                target={"_blank"}
                rel="noreferrer"
              >
                <DiplomSvg2 />
                <span className={s.dimploma__text}>
                  {/* {t("generals.diploma")} */}
                  {langEN.generals.diploma}
                </span>
              </a>
            )}
          </div>
        )}

        <div className={s.section__right}>
          {(data?.country_name || data?.city) && (
            <div className={`${s.valueRight} ${s.location}`}>
              {data.city ? `${data.city}, ` : ""}
              {data.country_name}
            </div>
          )}
          {data?.start_date !== data?.end_date &&
            (data?.start_date ||
              (data?.end_date && !data?.[`still_working`])) && (
              <div className={`${s.valueRight} ${s.date}`}>
                {data.start_date && data.start_date !== "0000-00-00" ? (
                  <>
                    {
                      ((date = parseDateDayMonYear(data.start_date)),
                      `${t(date.month)} ${date.year} ${
                        data?.end_date && !data?.[`still_working`] ? " -" : ""
                      }`)
                    }
                  </>
                ) : (
                  ""
                )}

                <br />
                {data?.end_date &&
                data?.end_date !== "0000-00-00" &&
                !data?.[`still_working`] ? (
                  <>
                    {
                      ((date = parseDateDayMonYear(data.end_date)),
                      `${t(date.month)} ${date.year}`)
                    }
                  </>
                ) : (
                  ""
                )}
              </div>
            )}
          {(data?.start_year || data?.end_year) &&
            data?.start_year !== data?.end_year && (
              <div className={classNames(s.value)}>
                {data.start_year}
                {data.end_year ? ` - ${data.end_year}` : ""}
              </div>
            )}
          {data?.start_date !== data?.end_date &&
            data?.start_date &&
            data?.end_date &&
            data.start_date !== "0000-00-00" &&
            data.end_date !== "0000-00-00" &&
            !data?.[`still_working`] && (
              <div className={classNames(s.howlong, s.value)}>
                {parseDateGetdiffDate(t, data.start_date, data.end_date)}
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

DetailedInformationItem.propTypes = {};

export default DetailedInformationItem;
