import { createSlice } from "@reduxjs/toolkit";

//db
import { mainLanguageData } from "../../DB/languageData";

const initialState = {
  loading: false,
  error: null,
  language: mainLanguageData?.key,
  isLocalizationUrl: null,
  isLoggin: localStorage.getItem("access_token_UCGA") || false,
};

export const app = createSlice({
  name: "myData",
  initialState,
  reducers: {
    setLoadingStatus: (state) => {
      state.loading = true;
    },
    setErrorMessage: (state, action) => {
      state.loading = true;
      state.error = action.payload;
    },
    clearLoadingAndError: (state) => {
      state.loading = false;
      state.error = null;
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    setIsLocalizationUrl: (state, action) => {
      state.isLocalizationUrl = action.payload;
    },
    setIsLoggin(state, action) {
      state.isLoggin = action.payload;
    },
  },
});

export const {
  setLoadingStatus,
  setErrorMessage,
  clearLoadingAndError,
  setLanguage,
  setIsLocalizationUrl,
  setIsLoggin,
} = app.actions;

export default app.reducer;
