import React from "react";
import { useSelector } from "react-redux";

//styles
import s from "./error.module.scss";

const Error = ({ dataError, errorRequestBitrixMessage }) => {
  const language = useSelector((state) => state.app.language);
  return (
    <div
      className={`${s.root} ${
        language === "en" ? s.rootFontOne : s.rootFontTwo
      }`}
    >
      {dataError?.response?.data ? (
        <>
          {errorRequestBitrixMessage && (
            <span>{errorRequestBitrixMessage}</span>
          )}
          <span>{dataError?.response?.data?.errors}</span>
          <br />
          <span> status {dataError?.response?.status}</span>
        </>
      ) : (
        <>
          <span>{dataError?.message}</span>
        </>
      )}
    </div>
  );
};

export default Error;
