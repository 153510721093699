import React, { useEffect, useRef } from "react";
import Slider from "react-slick";
import PropTypes from "prop-types";
import classNames from "classnames";

//styles
import "./custom-slider.scss";

//svg
import { CustomSliderArrowSvg } from "../svgHtml/CustomSliderArrowSvg";

const CustomSlider = ({ dots, hideNumOfSlide, children, left, className }) => {
  const sliderRef = useRef(null);
  const [totalSlides, setTotalSlides] = React.useState(0);
  const [currentSlide, setCurrentSlide] = React.useState(1);

  const onNextClick = () => {
    sliderRef.current.slickNext();
  };
  const onPrevClick = () => {
    sliderRef.current.slickPrev();
  };

  const settings = {
    customPaging: () => <div className={"custom-dots"}></div>,
    dots: dots,
    arrows: false,
    speed: 180,
    slidesToShow: 1,
    infinite: true,
    afterChange: (index) => setCurrentSlide(index + 1),
  };

  useEffect(() => {
    setTotalSlides(
      sliderRef.current.props.children
        ? sliderRef.current.props.children.length
        : 0
    );
  }, []);

  return (
    <div className={classNames("custom-slider", className)}>
      <div>
        <Buttons
          left={left}
          current={currentSlide}
          total={totalSlides}
          onPrevClick={onPrevClick}
          onNextClick={onNextClick}
          hideNumOfSlide={hideNumOfSlide}
        />
      </div>
      <Slider {...settings} ref={sliderRef}>
        {children}
      </Slider>
    </div>
  );
};

CustomSlider.propTypes = {
  dots: PropTypes.bool,

  hideNumOfSlide: PropTypes.bool,
  left: PropTypes.bool,
  className: PropTypes.string,
};

const Buttons = ({
  onNextClick,
  onPrevClick,
  total,
  current,
  left,
  hideNumOfSlide,
}) => {
  return (
    <div
      className={classNames("buttons-root", {
        left: left,
        hideNumOfSlide: hideNumOfSlide,
      })}
    >
      {!left && !hideNumOfSlide ? (
        <div className={"slide-num"}>
          {current}/{total}
        </div>
      ) : (
        ""
      )}
      <div className={"buttons"}>
        <div
          onClick={onPrevClick}
          className={classNames("button", { left: !left })}
        >
          <CustomSliderArrowSvg />
        </div>
        <div
          onClick={onNextClick}
          className={classNames("button", { right: left })}
        >
          <CustomSliderArrowSvg />
        </div>
      </div>
      {left && !hideNumOfSlide ? (
        <div className={"slide-num"}>
          {current}/{total}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
Buttons.propTypes = {
  onPrevClick: PropTypes.func,
  onNextClick: PropTypes.func,
  total: PropTypes.number,
  current: PropTypes.number,
  left: PropTypes.bool,
  hideNumOfSlide: PropTypes.bool,
};

export default CustomSlider;
