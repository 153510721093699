import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useSelector, useDispatch } from "react-redux";
import { useParams, NavLink } from "react-router-dom";

//styles
import s from "./ned-database-item.module.scss";
import breakpoints from "../../scss/vars";

//assets
import arrowLeft from "../../../assets/icons/ganeral/arrowLeft.svg";

//sections
import GeneralInformation from "./sections/GeneralInformation/GeneralInformation";
import PreviewInformation from "./sections/PreviewInformation/PreviewInformation";
import DetailedInformation from "./sections/DetailedInformation/DetailedInformation";

//ui
import { BackToProfile, Container } from "../../ui";
import Loader from "../../ui/Loader";
import Error from "../../ui/Error/Error";
import MetaTagsHelmet from "../../ui/MetaTagsHelmet/MetaTagsHelmet";

//redux
import { getNedDatabasePerson } from "../../../redux/NedDatabase/nedDatabase";
import { getSlugUser } from "../../../redux/MyProfile/myProfile";

//Routes
import { path, pathFC } from "../../App/Routes/paths";

//db
import { langEN } from "../../../DB/translationEN";

const NedDatabaseItem = ({ showBackToProfile, myProfile }) => {
  const dispatch = useDispatch();

  const local = useParams();

  const iphone = useMediaQuery({ maxWidth: breakpoints.smPlus });

  const language = useSelector((state) => state.app.language);

  const loading = useSelector((state) => state.nedDatabase.statusGetPerson);

  const error = useSelector((state) => state.nedDatabase.errorPerson);

  const nedDatabasePerson = useSelector(
    (state) => state.nedDatabase.nedDatabasePerson
  );

  const slugUser = useSelector((state) => state.myProfile.slugUser);

  const params = {
    // lang: language,
    lang: "en",
  };

  useEffect(() => {
    if (myProfile) {
      if (slugUser?.slug) {
        dispatch(getNedDatabasePerson(slugUser.slug, params));
      } else {
        dispatch(getSlugUser());
      }
    }
  }, [language, slugUser]);

  useEffect(() => {
    if (!myProfile) {
      dispatch(getNedDatabasePerson(local.id, params));
    }
  }, [language]);

  return (
    <>
      <MetaTagsHelmet
        title={`${nedDatabasePerson?.first_name} ${nedDatabasePerson?.last_name}`}
      />

      <main className={s.main}>
        <Container>
          {!loading ? (
            !error ? (
              <div>
                {showBackToProfile && !iphone && (
                  <NavLink
                    to={pathFC(path?.myData)}
                    className={s.backToProfile}
                  >
                    <BackToProfile prev />
                    <span>
                      {/* {t("generals.backToProfile")} */}
                      {langEN.generals.backToProfile}
                    </span>
                  </NavLink>
                )}

                {showBackToProfile && iphone && (
                  <NavLink
                    to={pathFC(path?.myData)}
                    className={s.backToProfileAdaptive}
                  >
                    <div className={s.img}>
                      <img src={arrowLeft} alt="arrow" />
                    </div>
                    <span>
                      {nedDatabasePerson?.first_name}{" "}
                      {nedDatabasePerson?.last_name}
                    </span>
                  </NavLink>
                )}

                <div className={s.root}>
                  <div className={s.preview}>
                    <PreviewInformation
                      data={nedDatabasePerson}
                      myProfile={myProfile}
                    />
                  </div>
                  <div className={s.main}>
                    <GeneralInformation data={nedDatabasePerson} />
                    <DetailedInformation data={nedDatabasePerson} />
                  </div>
                </div>
              </div>
            ) : (
              <Error dataError={error} />
            )
          ) : (
            <Loader />
          )}
        </Container>
      </main>
    </>
  );
};

export default NedDatabaseItem;
