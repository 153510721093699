//db
import { mainLanguageData } from "../../../DB/languageData";

//utils
import { findUrlLocalization } from "../../../utils/findUrlLocalization";

export const path = {
  home: `/`,
  education: "/education",
  professors: "/education/professors",
  alumni: "/alumni",
  nedDatabase: "/ned-database",
  services: "/services",
  ourBoard: "/our-board",
  ourTeam: "/our-team",
  news: "/news",
  applicationForm: "/application-form",
  thanks: "/thanks",
  wellDone: "/well-done",
  login: "/login",
  resetPassword: "/reset-password",
  myData: "/my-data",
  greatJob: "/great-job",
  studyingMaterials: "/studying-materials",
  graduateWork: "/graduate-work",
  myProfile: "/my-profile",
  registration: "/registrationucga",
  updatePassword: "/update-password",
  privateNews: "/private-news",
};

export const pathFC = (pathName) => {
  const _language = localStorage.getItem("language_UCGA");

  const location = window.location;

  const pathSplit = location.pathname.split("/");

  const isLocalizationUrl = findUrlLocalization(pathSplit);

  const language = _language || isLocalizationUrl || "en";

  const localizeRoutePath = (path) =>
    language === mainLanguageData.key ? `${path}` : `/${language}${path}`;

  return localizeRoutePath(pathName);
};
