import axios from "axios";
import { addLead, assignedById } from "./bitrixAPIConsts";

export const createLead = async (
  userInfo,
  type,
  typeName,
  setErrorRequestBitrix,
  setSuccessRequestBitrix,
  setLoadingRequestBitrix,
  googleId,
  utmParams
) => {
  const date = new Date();
  const types = {
    program: "on the program",
    sendOffer: "send offer to",
    servises: "services",
    initiative: "initiative",
  };

  let description = "";
  if (userInfo.comment) {
    description += `Comments: ${userInfo.comment}`;
  } else if (userInfo.question) {
    description += `Питання: ${userInfo.question}`;
  }

  if (userInfo.itemForOffer) {
    description += `\n Пропозиція щодо: ${userInfo.itemForOffer}`;
  }

  if (googleId) {
    description += `\ngaClientId: ${googleId}`;
  }

  let fields = {
    TITLE: `${types[type]} ${typeName ? `"${typeName}"` : ""} from
    ${userInfo.firstName} ${userInfo.lastName}`,
    NAME: userInfo.firstName,
    LAST_NAME: userInfo.lastName,
    DATE_CREATE: date,
    OPENED: "Y",
    ASSIGNED_BY_ID: assignedById,
    SOURCE_DESCRIPTION: description,
    // SOURCE_ID: "STORE",
    EMAIL: [
      {
        VALUE: userInfo.email,
      },
    ],
    PHONE: [
      {
        VALUE: userInfo.phone,
      },
    ],
  };

  if (utmParams) {
    for (const paramKey in utmParams) {
      if (utmParams.hasOwnProperty(paramKey)) {
        fields = {
          ...fields,
          [paramKey]: utmParams[paramKey],
        };
      }
    }
  }
  setLoadingRequestBitrix(true);
  return axios
    .post(`${addLead}`, {
      fields,
    })
    .then((response) => {
      setSuccessRequestBitrix(response);
      setLoadingRequestBitrix(false);
    })
    .catch((error) => {
      setErrorRequestBitrix(error);
      setLoadingRequestBitrix(false);
    });
};
