import axios from "axios";

//export const baseURL = "https://ucga.wecreateyour.website/api"; -- old back domain
//export const baseURL = "http://localhost/UCGA-Back/public/api";
//export const baseURLSingle = "http://localhost/UCGA-Back/public";
export const baseURL = "https://back.ucga.com.ua/api";
export const baseURLSingle = "https://back.ucga.com.ua";

export const combineURLs = (baseURL, relativeURL) => {
  return relativeURL
    ? baseURL.replace(/\/+$/, "") + "/" + relativeURL.replace(/^\/+/, "")
    : baseURL;
};

export const deleteRequest = ({
  url,
  data,
  success,
  setError,
  headers,
  params,
}) => {
  axios
    .delete(`${combineURLs(baseURL, url)}`, { headers, params, data })
    .then((res) => {
      success(res.data);
    })
    .catch((err) => {
      err && setError(err);
    });
};

export const postRequest = ({
  url,
  obj,
  success,
  setError,
  headers,
  params,
}) => {
  axios
    .post(`${combineURLs(baseURL, url)}`, obj, { headers, params })
    .then((res) => {
      success(res.data);
    })
    .catch((err) => {
      err && setError(err);
    });
};

export const getRequest = ({
  url,
  success,
  setError,
  params,
  headers,
  data,
}) => {
  axios
    .get(`${combineURLs(baseURL, url)}`, {
      headers,
      params,
      data,
    })
    .then((res) => success(res.data))
    .catch((err) => {
      setError(err);
    });
};
