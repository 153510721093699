import styled from "styled-components";

const FontSizeStyled = styled.div`
  font-size: ${(props) => props.fontSize.xl};
  line-height: ${(props) => props.lineHeight.xl};
  @media (max-width: 1024px) {
    font-size: ${(props) => props.fontSize.lg} !important;
    line-height: ${(props) => props.lineHeight.lg};
  }
  @media (max-width: 768px) {
    font-size: ${(props) => props.fontSize.md} !important;
    line-height: ${(props) => props.lineHeight.md};
  }
  @media (max-width: 586px) {
    font-size: ${(props) => props.fontSize.mobile} !important;
    line-height: ${(props) => props.lineHeight.mobile};
  }
  @media (max-width: 576px) {
    font-size: ${(props) => props.fontSize.mdMinus} !important;
    line-height: ${(props) => props.lineHeight.mdMinus};
  }
  @media (max-width: 464px) {
    font-size: ${(props) => props.fontSize.smPlus} !important;
    line-height: ${(props) => props.lineHeight.smPlus};
  }
  @media (max-width: 320px) {
    font-size: ${(props) => props.fontSize.sm} !important;
    line-height: ${(props) => props.lineHeight.sm};
  }
`;

FontSizeStyled.defaultProps = {
  lineHeight: {
    xl: "142%",
  },
  fontSize: {
    xl: "22px",
  },
};

export default FontSizeStyled;
