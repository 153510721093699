import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

//styles
import s from "./notFound.module.scss";

//ui
import { Button } from "../../ui/index";

//Routes
import { path, pathFC } from "../../App/Routes/paths";

const NotFound = () => {
  const { t } = useTranslation();

  const history = useHistory();

  const language = useSelector((state) => state.app.language);

  return (
    <main
      className={`${s.root} ${
        language === "en" ? s.rootFontOne : s.rootFontTwo
      }`}
    >
      {t("errors.notFound")}
      <br />
      {t("errors.error404")}

      <Button
        onClick={() => history.push(pathFC(path.home))}
        secondary
        width={"250px"}
        sidePadding={0}
        marginTop={"25px"}
      >
        {t("btnTitle.homePage")}
      </Button>
    </main>
  );
};

export default NotFound;
