import styled from "styled-components";

const FontSizeStyled = styled.h2`
  font-size: ${(props) => props.fontSize.xl};
  max-width: ${(props) => props.width.xl};
  & svg {
    display: block;
    margin-top: 8px;
  }

  @media (max-width: 1024px) {
    font-size: ${(props) => props.fontSize.lg} !important;
    max-width: ${(props) => props.width.lg};
  }
  @media (max-width: 768px) {
    font-size: ${(props) => props.fontSize.md} !important;
    max-width: ${(props) => props.width.md};
  }
  @media (max-width: 576px) {
    font-size: ${(props) => props.fontSize.mobile} !important;
    max-width: ${(props) => props.width.mobile};
    svg {
      width: 180px;
    }
  }
  @media (max-width: 576px) {
    font-size: ${(props) => props.fontSize.mdMinus} !important;
    max-width: ${(props) => props.width.mdMinus};
    svg {
      width: 180px;
    }
  }
  @media (max-width: 464px) {
    font-size: ${(props) => props.fontSize.smPlus} !important;
    max-width: ${(props) => props.width.smPlus};

    svg {
      margin-top: 5px;
      width: 130px;
    }
  }
  @media (max-width: 320px) {
    font-size: ${(props) => props.fontSize.sm} !important;
    max-width: ${(props) => props.width.sm};

    svg {
      width: 130px;
    }
  }
`;

FontSizeStyled.defaultProps = {
  width: {
    xl: "100%",
  },
  fontSize: {
    xl: "120px",
  },
};

export default FontSizeStyled;
