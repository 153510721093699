import React from "react";
import { Helmet } from "react-helmet-async";

const MetaTagsHelmet = ({ title }) => {
  return (
    <Helmet>
      <title>{title ? title : "UCGA"}</title>
      <link
        rel="alternate"
        hreflang="x-default"
        href={`${window.location.origin}`}
      />
      <link rel="alternate" hreflang="en" href={`${window.location.origin}`} />
      <link
        rel="alternate"
        hreflang="uk-ua"
        href={`${window.location.origin}/uk`}
      />
    </Helmet>
  );
};

export default MetaTagsHelmet;
