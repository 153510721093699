import React from "react";
import { useMediaQuery } from "react-responsive";

//styles
import common from "../common1220.module.scss";
import s from "./member-pop-up.module.scss";
import breakpoints from "../../../scss/vars";

//ui
import PopUpCover from "../PopUpCover/PopUpCover";
import CustomSlider from "../../Sliders/CustomSlider";
import AdaptiveTitle from "../../AdaptiveTitle/AdaptiveTitle";
import AdaptiveInformationText from "../../AdaptiveInfomrationText/AdaptiveInformationText";
import { useTranslation } from "react-i18next";

//API
import { baseURLSingle } from "../../../../API/api";

const MemberPopUp = ({ info, onCloseClick }) => {
  const { t } = useTranslation();
  let images = null;

  const md = useMediaQuery({ maxWidth: breakpoints.md });

  images = JSON.parse(info.images);
  return (
    <PopUpCover primaryBackground onCloseClick={onCloseClick} width={"1220px"}>
      <div className={common.root}>
        <div className={s.info}>
          <div className={s.wrapperSilder}>
            {images?.length > 0 && (
              <CustomSlider dots={false}>
                {images.map((img, index) => {
                  return (
                    <img
                      src={img}
                      alt="img"
                      key={index}
                    />
                  );
                })}
              </CustomSlider>
            )}
          </div>
          <div className={s.text}>
            {(info?.first_name || info?.last_name) && (
              <div className={s.name}>
                <AdaptiveTitle
                  fontSize={{ xl: "80px", smPlus: "45px" }}
                  widthUnderline={100}
                  width={{ xl: "400px" }}
                >
                  {t(info.first_name)} {t(info?.last_name)}
                </AdaptiveTitle>
              </div>
            )}

            <AdaptiveInformationText
              fontSize={{ xl: "18px" }}
              width={md ? "100%" : "490px"}
              marginTop={"20px"}
              className={s.memberPopUp}
            >
              {t(info?.info)}
            </AdaptiveInformationText>
          </div>
        </div>
      </div>
    </PopUpCover>
  );
};

export default MemberPopUp;
