import React from "react";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

//styles
import s from "./plan.module.scss";
import points from "../../scss/vars";

//ui
import { Button } from "../index";
import AdaptiveInformationText from "../AdaptiveInfomrationText/AdaptiveInformationText";

//global
import { path, pathFC } from "../../App/Routes/paths";

//API
import { baseURLSingle } from "../../../API/api";

const Plan = ({ title, image = null, includes, btn }) => {
  const iphone = useMediaQuery({ maxWidth: points.smPlus });
  const md = useMediaQuery({ maxWidth: points.md });
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div className={s.root}>
      <div className={s.wrraperImage}>
        {image && (
          <div className={s.image}>
            <img src={`${baseURLSingle}/${image}`} alt="icon" />
          </div>
        )}

        <div className={s.title}>
          <AdaptiveInformationText fontSize={{ xl: "42px", smPlus: "28px" }}>
            {title}
          </AdaptiveInformationText>
        </div>
      </div>
      <div className={s.includes}>
        <AdaptiveInformationText fontSize={{ xl: "14px" }} marginTop={"30px"}>
          {includes}
        </AdaptiveInformationText>
      </div>
      {btn?.title && (
        <div className={s.wrraperBtn}>
          <Button
            tertiary
            marginTop={"45px"}
            width={iphone ? "100%" : "250px"}
            sidePadding={0}
            onClick={() =>
              history.push(pathFC(`${path.education}/${btn?.path}`))
            }
            active={md ? true : false}
          >
            {t(btn?.title)}
          </Button>
        </div>
      )}
    </div>
  );
};

export default Plan;
