import { createSlice } from "@reduxjs/toolkit";

//api
import { postRequest } from "../../API/api";

export const postResetPassword = (obj,params) => (dispatch) => {
  dispatch(setLoadingPostStatusResetPassword());
  const success = (data) => {
    dispatch(successPostResetPassword(data));
  };

  const setError = (error) => {
    dispatch(setErrorPostResetPassword(error));
  };

  postRequest({
    url: `auth/forgot_password`,
    success,
    setError,
    obj,
    params
  });
};

const initialState = {
  dataPostStatusResetPassword: {},
  loadingPostStatusResetPassword: false,
  errorPostStatusResetPassword: null,
};

export const myProfileResetPassword = createSlice({
  name: "myProfileResetPassword",
  initialState,
  reducers: {
    setLoadingPostStatusResetPassword(state) {
      state.status = true;
      state.error = null;
    },
    successPostResetPassword(state, action) {
      state.dataPostStatusResetPassword = action.payload;
      state.status = false;
      state.errorPostStatusResetPassword = null;
    },
    setErrorPostResetPassword(state, action) {
      state.errorPostStatusResetPassword = action.payload;
      state.status = false;
    },
  },
});
export const {
  setLoadingPostStatusResetPassword,
  successPostResetPassword,
  setErrorPostResetPassword,
} = myProfileResetPassword.actions;

export default myProfileResetPassword.reducer;
