import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import {
  Link,
  NavLink,
  // NavLink
} from "react-router-dom";
// import { useHistory } from "react-router-dom";

//styles
import s from "./header.module.scss";
import { useEffect } from "react";

//global
import { path, pathFC } from "../../../App/Routes/paths";

//rework

export const AdaptiveDropdown = ({
  onLinkClick,
  dropdownTitle,
  dropdown,
  isOpenMobileMenu,
  dropdownMainLink,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!isOpenMobileMenu) {
      setIsOpen(false);
    }
  }, [isOpenMobileMenu]);
  return (
    <div className={s.adaptiveDropdown}>
      <div className={s.dropdownText} onClick={() => setIsOpen(!isOpen)}>
        <NavLink to={dropdownMainLink} className={s.text} onClick={onLinkClick}>
          {t(dropdownTitle)}
        </NavLink>
        <svg
          className={classNames({ [s.reverse]: isOpen, [s.normal]: !isOpen })}
          width="8"
          height="5"
          viewBox="0 0 8 5"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.00089 4L3.64739 4.35361L4.001 4.70711L4.3545 4.3535L4.00089 4ZM1.3535 0.646394C1.15821 0.451161 0.841626 0.451208 0.646394 0.6465C0.451161 0.841791 0.451208 1.15837 0.646499 1.35361L1.3535 0.646394ZM7.35361 1.3535C7.54884 1.15821 7.54879 0.841627 7.3535 0.646395C7.15821 0.451161 6.84163 0.451209 6.64639 0.6465L7.35361 1.3535ZM4.3544 3.64639L1.3535 0.646394L0.646499 1.35361L3.64739 4.35361L4.3544 3.64639ZM6.64639 0.6465L3.64729 3.6465L4.3545 4.3535L7.35361 1.3535L6.64639 0.6465Z"
            fill="#0D5B7A"
          />
        </svg>
      </div>
      {isOpen && (
        <div className={s.adaptiveDropdownItems}>
          <ul>
            {dropdown.length > 0
              ? dropdown.map((item) => {
                  return (
                    <li
                      key={item?.id}
                      onClick={() => {
                        onLinkClick();
                        setIsOpen(false);
                      }}
                    >
                      <Link
                        to={pathFC(`${path.education}/${item?.slug}`)}
                        className={s.dropdownLink}
                      >
                        {item?.name}
                      </Link>
                    </li>
                  );
                })
              : "not"}
          </ul>
        </div>
      )}
    </div>
  );
};
