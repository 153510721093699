import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

//styles
import s from "./text.module.scss";

const TextA = ({ children, disabled }) => {
  return (
    <div
      className={classNames(s.root, {
        [s.active]: !disabled,
        [s.disable]: disabled,
      })}
    >
      {children}
    </div>
  );
};

TextA.propTypes = {
  children: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default TextA;
