export const newsFiltersData = [
  {
    id: 0,
    title: "All",
    category: 0,
  },
  {
    id: 1,
    title: "News",
    category: 1,
  },
  {
    id: 2,
    title: "Video Content",
    category: 2,
  },
  {
    id: 3,
    title: "Photo reports",
    category: 3,
  },
];
