import React from "react";
import classNames from "classnames";

//styles
import s from "./preview-information.module.scss";

//assets
import starGray from "../../../../../assets/icons/ganeral/starGray.svg";

//ui
import { Button } from "../../../../ui";
import SendOffer from "../../../../ui/PopUps/SendOffer/SendOffer";
import ShowModalOnClick from "../../../../ui/PopUps/ShowModalOnClick";

//svg
import {
  FacebookSvg,
  LinkedInSvg,
} from "../../../../ui/svgHtml/SocialsNetworkSvg";

//API
import { baseURLSingle } from "../../../../../API/api";

//db
import { langEN } from "../../../../../DB/translationEN";

const PreviewInformation = ({ data, myProfile }) => {
  const achievements =
    (data?.achievements && data?.achievements?.filter((item) => item)) || [];

  const organizations =
    (data?.organizations && data?.organizations?.filter((item) => item)) || [];

  console.log(data, "data");
  return (
    <div className={s.root}>
      {data?.image && (
        <div className={s.image}>
          <img src={`${baseURLSingle}/${data.image}`} alt="person" />{" "}
        </div>
      )}

      <div className={s.data}>
        {data?.ucga_alumnus?.code && (
          <div className={s.categoryOuter}>
            <div className={s.category}>
              <div className={s.category__value}>
                {/* UCGA {t("generals.alumnus")}{" "} */}
                UCGA {langEN.generals.alumnus}{" "}
                <span className={s.category__title}>
                  {data?.ucga_alumnus?.code}
                </span>
              </div>
            </div>
          </div>
        )}

        {data?.info?.UCGACertificate && (
          <div className={s.categoryOuter}>
            <div className={s.category}>
              <div className={s.category__value}>
                {/* UCGA {t("generals.certificate")} */}
                UCGA {langEN.generals.certificate}
              </div>
            </div>
          </div>
        )}

        {organizations?.length > 0 && (
          <div className={s.categoryOuter}>
            <div className={s.category}>
              <div className={s.category__title}>
                {/* {t("organizationsAndAchievement.organizations")} */}
                {langEN.organizationsAndAchievement.organizations}
              </div>
              <div className={s.category__value}>
                {organizations.map((item, index) => (
                  <span key={index} className={s.text}>
                    {item}
                    {index + 1 !== organizations.length && ","}{" "}
                  </span>
                ))}
              </div>
            </div>
          </div>
        )}

        {achievements?.length > 0 && (
          <div className={s.categoryOuter}>
            <div className={s.category}>
              <div className={s.category__title}>
                {/* {t("organizationsAndAchievement.achievements")} */}
                {langEN.organizationsAndAchievement.achievements}
              </div>
              {achievements.map((item, index) => {
                if (item === "") {
                  return null;
                } else {
                  return (
                    <div
                      className={classNames(
                        s.category__value,
                        s.category__valueSvg
                      )}
                      key={index}
                    >
                      <div className={s.wrapperImg}>
                        <img src={starGray} alt="starGray" />
                      </div>
                      <div className={s.text}>
                        {" "}
                        <span>{item}</span>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        )}

        {(data?.linked_in || data?.facebook) && (
          <div className={classNames(s.category, s.categoryOneLine)}>
            <div
              className={classNames(
                s.category__title,
                s.categoryOneLine__title
              )}
            >
              {/* {t("generals.social")} */}
              {langEN.generals.social}
            </div>
            <div className={classNames(s.category__value)}>
              {data?.facebook && (
                <a href={data.facebook} target="_blank" rel="noreferrer">
                  <FacebookSvg />
                </a>
              )}
              {data?.linked_in && (
                <a href={data.linked_in} target="_blank" rel="noreferrer">
                  <LinkedInSvg />
                </a>
              )}
            </div>
          </div>
        )}
        {!myProfile && (
          <ShowModalOnClick
            PopUp={SendOffer}
            info={` ${data?.first_name}
          ${data?.last_name}`}
          >
            <div className={s.btn}>
              <Button width={"100%"} sidePadding={48} secondary>
                {/* {t("modal.titleModal.sendOffer")} */}
                {langEN.modal.titleModal.sendOffer}
              </Button>
            </div>
          </ShowModalOnClick>
        )}
      </div>
    </div>
  );
};

export default PreviewInformation;
