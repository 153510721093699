import { createSlice } from "@reduxjs/toolkit";

import { getRequest } from "../../API/api";

export const getEducation = (slug, params) => (dispatch) => {
  dispatch(setLoadingStatus());

  const success = (data) => {
    dispatch(successEducation(data.data));
    dispatch(successGetlanguageRequest(params.lang));
  };

  const setError = (error) => {
    dispatch(setErrorMessage(error));
  };

  getRequest({
    url: `education_page/${slug}`,
    success,
    setError,
    params,
  });
};

const initialState = {
  pageData: {},
  status: null,
  error: null,
  filtersObjForBack: null,
  getlanguageRequest: null,
};

export const education = createSlice({
  name: "educationPageData",
  initialState,
  reducers: {
    setLoadingStatus(state, action) {
      state.status = true;
      state.error = false;
      state.pageData = null;
    },
    setErrorMessage(state, action) {
      state.error = action.payload;
      state.status = false;
    },
    successEducation(state, action) {
      state.pageData = action.payload;
      state.status = false;
      state.error = null;
    },
    successGetlanguageRequest(state, action) {
      state.getlanguageRequest = action.payload;
    },
  },
});

export const {
  setLoadingStatus,
  setErrorMessage,
  successEducation,
  successGetlanguageRequest,
} = education.actions;

export default education.reducer;
