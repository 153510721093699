import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

//styles
import s from "./input.module.scss";

//components
import StyledInputWidth from "../StyledInputWidth/StyledInputWidth";

const Input = ({
  width,
  label,
  id,
  type,
  name,
  register,
  error,
  required,
  placeholder,
  className,
  marginTop,
  errorFromBack,
  disabled,
  aditionalErrorFromBack,
}) => {
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);
  const [typefieldPasword, setTypefieldPasword] = useState("password");

  const showHidePasswoedHandler = () => {
    setShowPassword((prev) => !prev);
    showPassword
      ? setTypefieldPasword("password")
      : setTypefieldPasword("text");
  };
  return (
    <StyledInputWidth width={width} marginTop={marginTop}>
      {register && name ? (
        <div className={classNames(s.root, className)}>
          {label ? (
            <label htmlFor={label}>
              {label}
              {required ? <span className={s.required}>*</span> : ""}
            </label>
          ) : (
            ""
          )}
          <div className={s.wrapperInput}>
            <input
              id={label}
              placeholder={placeholder}
              name={name}
              type={type === "password" ? typefieldPasword : type}
              {...register(name)}
              disabled={disabled}
            />
            {type === "password" && (
              <div
                className={s.showHide}
                onClick={() => showHidePasswoedHandler()}
              >
                {showPassword ? t("generals.hide") : t("generals.show")}
              </div>
            )}
          </div>
          {!!error && <div className={s.error}>{error?.message}</div>}
          {errorFromBack && <div className={s.error}>{`${errorFromBack[0]} ${aditionalErrorFromBack || ''}`}</div>}
        </div>
      ) : (
        <div className={s.root}>
          {label ? (
            <label>
              {label}
              {required ? "*" : ""}
            </label>
          ) : (
            ""
          )}
          <input id={id} placeholder={placeholder} name={name} type={type} />
        </div>
      )}
    </StyledInputWidth>
  );
};

export default Input;
