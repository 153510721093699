import React from "react";

const ShowModalOnClick = ({ children, PopUp, onSaveClick, info }) => {
  const [show, setShow] = React.useState(false);

  return (
    <>
      {show && PopUp && (
        <PopUp
          onSaveClick={onSaveClick}
          onCloseClick={() => setShow(false)}
          info={info || ""}
        />
      )}
      <div onClick={() => setShow(true)}>{children}</div>
    </>
  );
};

export default ShowModalOnClick;
