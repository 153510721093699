import React from "react";

export const CustomSliderArrowSvg = () => (
  <svg
    width="13"
    height="24"
    viewBox="0 0 13 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2 10.5C2.82843 10.5 3.5 11.1716 3.5 12C3.5 12.8284 2.82843 13.5 2 13.5L2 10.5ZM0.43934 13.0607C-0.146447 12.4749 -0.146447 11.5251 0.43934 10.9393L9.98528 1.3934C10.5711 0.807611 11.5208 0.807611 12.1066 1.3934C12.6924 1.97919 12.6924 2.92893 12.1066 3.51472L3.62132 12L12.1066 20.4853C12.6924 21.0711 12.6924 22.0208 12.1066 22.6066C11.5208 23.1924 10.5711 23.1924 9.98528 22.6066L0.43934 13.0607ZM2 13.5H1.5L1.5 10.5H2L2 13.5Z" />
  </svg>
);
